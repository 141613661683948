export default [
  {
    name: "SUPERB: Digital Certificate in 120 minutes",
    description:
      "Our doctor buddy will review your documents and make final decision on the certificate. Your certificate will be signed and sealed by a registered M.B.B.S doctor. The certificate will be sent via email, usually delivered within two working hours.",
    price: 1300,
    prescriptionPrice: 250,
    totalPrice: 2500,
    abroadPrice: 2100,
    abroadTotalPrice: 5000,
    discountPercent: 40,
    priority: "high",
    display: true,
    assignAfter: 0,
  },
  {
    name: "GOOD: Digital Certificate in 48 hours",
    description:
      "This will be reviewed and signed by an Indian doctor and sent via email, usually delivered in two days.",
    price: 1000,
    prescriptionPrice: 250,
    totalPrice: 2000,
    abroadPrice: 2000,
    abroadTotalPrice: 5000,
    discountPercent: 40,
    priority: "high",
    display: true,
    assignAfter: 12,
  },
];
