import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "./style.css";

import HeaderComponent from "./header";
import AdminZoneComponent from "./admin_zone/component";
import { signout, update, getId } from "firebase_config";
import { SetUser, SetLoggedIn, SetDbUser } from "store/actions";
import { Tables, Routes } from "constants/index";

function SettingsComponent({
  currentUser,
  bindLoggedIn,
  bindUser,
  bindDbUser,
}) {
  const history = useHistory();
  const isLevelOneDoctor = currentUser.roles.find(
    (role) => role === "levelOne"
  );
  const isLevelTwoDoctor = currentUser.roles.find(
    (role) => role === "levelTwo"
  );

  useEffect(async () => {
    const dbUser = await getId(Tables.USERS, currentUser.id);
    await bindDbUser({ ...dbUser, id: currentUser.id });
  }, []);

  const signoutUser = async () => {
    await signout();
    await bindLoggedIn(false);
    await bindDbUser(null);
    await bindUser(null);
    sessionStorage.clear();
    history.push("/");
  };

  const deactivateUser = async () => {
    if (window.confirm("Are you sure to deactivate?")) {
      const res = await update("users", currentUser.id, { deactivate: true });
      await bindDbUser({ ...currentUser, deactivate: true });
      if (res.status === 200) {
        alert("Successfully deactivated");
        await signoutUser();
      } else {
        alert("Successfully went wrong. Try later.");
      }
    }
  };

  const deleteUser = async () => {
    alert(
      "To delete your account, please give us an email at team@perlainfotech.com"
    );
    // await remove('users', dbUser.id)
    // await removeProfile()
    // history.push('/profile_deleted')
  };

  const comeBackAlert = async () => {
    alert("We work hard for you here. Come back later please.");
    // await remove('users', dbUser.id)
    // await removeProfile()
    // history.push('/profile_deleted')
  };

  const contactUs = async () => {
    alert("Contact us at team@perlainfotech.com");
  };

  const inviteFriend = async () => {
    alert("Share this app link - https://paygames.uk");
  };

  // const installPrompt = () => {
  //   deferredPrompt.prompt()
  //   deferredPrompt.userChoice().then((choiceResult) => {
  //     if (choiceResult.outcome === 'accepted') {
  //       console.log('User accepted A2HS prompt')
  //     } else {
  //       console.log('User denied A2HS prompt')
  //     }
  //     deferredPrompt = null
  //   })
  // }

  // const reload = () => window.location.reload();

  const copyToClipboard = (text, successMsg) => {
    navigator.clipboard.writeText(text);
    toast.success(successMsg);
  };

  return (
    <div className="dashboard-content">
      <HeaderComponent />
      <div className="container settings-wrapper desktop-align-center pt-4">
        <div className="section">
          <div className="section-header">Personal zone</div>
          <ul className="section-list">
            <li>
              <Link
                to={Routes.APP_SETTINGS_EDIT_PROFILE}
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">Edit profile</span>
              </Link>
            </li>
            <li>
              <a
                href="https://www.trustpilot.com/evaluate/medoc.life"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black"
              >
                <span className="option-name">Leave a review</span>
              </a>
            </li>
            <li className="d-none">
              <Link
                to="/app/settings/change_password"
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">Change password</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className={isLevelOneDoctor ? "section" : "d-none"}>
          <div className="section-header">
            Virtual Clinic
            <span className="badge badge-primary pull-right fw-500 ml-2">
              Level One
            </span>
          </div>
          <ul className="section-list">
            <li>
              <Link
                to={Routes.APP_DC_PUBLIC_APPOINTMENTS}
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">Appointments</span>
                <i className="option-name fa fa-arrow-right pull-right pl-2" />
              </Link>
            </li>
            <li className="">
              <Link
                to={Routes.APP_EDIT_VIRTUAL_CLINIC}
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">Edit Clinic Details</span>
              </Link>
            </li>
            <li>
              <Link
                to={Routes.APP_DC_PUBLIC_ANALYTICS}
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">Monthly Stats</span>
              </Link>
            </li>
            <li
              className="d-none"
              onClick={(e) =>
                copyToClipboard(
                  `https://medoc.life/dr/${currentUser.username}`,
                  "Virtual Clinic Url Copied!"
                )
              }
            >
              <div className="option-name pointer">Copy Virtual Clinic Url</div>
            </li>
            <li>
              <a
                href="https://docs.google.com/document/d/1hO2hO8SuJcAF8e6bbIjgN8dmKFi4hXgBLymQuTrWdJw/edit?usp=sharing"
                target="_blank"
                rel="noreferrer noopener"
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">
                  Help Guide <i className="fa fa-question-circle-o" />
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div className={isLevelTwoDoctor ? "section" : "d-none"}>
          <div className="section-header">
            Medoc Doctor
            <span className="badge badge-secondary pull-right fw-500 ml-2">
              Level Two
            </span>
          </div>
          <ul className="section-list">
            <li>
              <Link
                to="/app/requests"
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">Certificates</span>
                <i className="option-name fa fa-arrow-right pull-right pl-2" />
              </Link>
            </li>
            <li>
              <Link
                to={Routes.APP_SETTINGS_EDIT_PROFILE}
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">Edit Profile</span>
              </Link>
            </li>
            <li>
              <Link
                to={Routes.APP_SETTINGS_ANALYTICS}
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">Monthly Stats</span>
              </Link>
            </li>
            <li>
              <a
                href="https://docs.google.com/document/d/1otcDdFlhf6L9t-Npl50mXSohKKCmimXkkQ-G0uCPYLo/edit?usp=sharing"
                target="_blank"
                rel="noreferrer noopener"
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <span className="option-name">
                  Help Guide <i className="fa fa-question-circle-o" />
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div className="section">
          <div className="section-header">Support zone</div>
          <ul className="section-list">
            <li onClick={(e) => alert("Coming soon")}>
              <span className="option-name">
                Refer & Earn / Our Partners{" "}
                <span className="badge badge-success fw-500 ml-2">New</span>
              </span>
            </li>
            <li>
              <a
                href={`https://web.whatsapp.com/send?phone=+919490087678&text=${encodeURI(
                  "Hi team! I need help."
                )}&app_absent=0`}
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <div className="option-name">Chat via Whatsapp</div>
              </a>
            </li>
          </ul>
        </div>
        <div className="section d-none">
          <div className="section-header">Support Zone</div>
          <ul className="section-list">
            <li>
              <Link to="/app/settings" className="row">
                <img
                  src={require("assets/svgs/Workflow.svg").default}
                  className="scale-0-9 col-2"
                  alt="Workflow"
                />
                <span className="option-name col-8 bn">Install app</span>
                <img
                  src={require("assets/svgs/AngleRight.svg").default}
                  className="option-open col-2 d-none"
                  alt="Open"
                />
              </Link>
            </li>
            <li>
              <Link to="/app/settings" className="row" onClick={inviteFriend}>
                <img
                  src={require("assets/svgs/Workflow.svg").default}
                  className="scale-0-9 col-2"
                  alt="Workflow"
                />
                <span className="option-name col-8">Invite my friends</span>
                <img
                  src={require("assets/svgs/AngleRight.svg").default}
                  className="option-open col-2 d-none"
                  alt="Open"
                />
              </Link>
            </li>
            <li className="">
              <Link to="/app/settings" className="row" onClick={comeBackAlert}>
                <img
                  src={require("assets/svgs/Workflow.svg").default}
                  className="scale-0-9 col-2"
                  alt="Workflow"
                />
                <span className="option-name col-8">App workflow</span>
                <img
                  src={require("assets/svgs/AngleRight.svg").default}
                  className="option-open col-2"
                  alt="Open"
                />
              </Link>
            </li>
            <li className="">
              <Link to="/app/settings" className="row" onClick={comeBackAlert}>
                <img
                  src={require("assets/svgs/Faq.svg").default}
                  className="scale-1-1 col-2"
                  alt="Faq"
                />
                <span className="option-name col-8">Faq's</span>
                <img
                  src={require("assets/svgs/AngleRight.svg").default}
                  className="option-open col-2"
                  alt="Open"
                />
              </Link>
            </li>
            <li>
              <Link to="/app/settings" className="row" onClick={contactUs}>
                <img
                  src={require("assets/svgs/Support.svg").default}
                  className="scale-1-3 col-2"
                  alt="Support"
                />
                <span className="option-name col-8">Contact us</span>
                <img
                  src={require("assets/svgs/AngleRight.svg").default}
                  className="option-open col-2 d-none"
                  alt="Open"
                />
              </Link>
            </li>
          </ul>
        </div>
        <div className="section">
          <div className="section-header">Cautious zone</div>
          <ul className="section-list">
            <li>
              <div className="d-flex flex-row align-items-center justify-content-between">
                <span
                  className="option-name"
                  htmlFor="customSwitch1"
                  onClick={signoutUser}
                >
                  Logout
                </span>
              </div>
            </li>
            <li className="d-none">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <span
                  className="option-name"
                  htmlFor="customSwitch1"
                  onClick={deactivateUser}
                >
                  Deactivate account
                </span>
              </div>
            </li>
            <li onClick={deleteUser} className="d-none">
              <div className="row pointer">
                <img
                  src={require("assets/svgs/DeleteAccount.svg").default}
                  className="scale-0-9 col-2"
                  alt="DeleteAccount"
                />
                <span className="option-name col-8">Delete account</span>
                <img
                  src={require("assets/svgs/AngleRight.svg").default}
                  className="option-open col-2 d-none"
                  alt="Open"
                />
              </div>
            </li>
          </ul>
        </div>
        {currentUser.admin && <AdminZoneComponent currentUser={currentUser} />}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    bindLoggedIn: (content) => dispatch(SetLoggedIn(content)),
    bindUser: (content) => dispatch(SetUser(content)),
    bindDbUser: (content) => dispatch(SetDbUser(content)),
  };
};

export default connect(null, mapDispatchToProps)(SettingsComponent);
