import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import "./style.css";

import HeaderComponent from "../header";
import {
  add,
  update,
  uploadFile,
  removeFile,
  getQuery,
  firestore,
} from "firebase_config";
import { Tables, Routes, Products } from "constants/index";

const currency = "INR";
const products = Products.sickLeaveForWork;
const reasons = [
  "Migraine",
  "Period pain",
  "Common cold and flu",
  "Back pain",
  "Headache",
  "Anxiety, depression or stress",
  "Other",
];

const CreatePostComponent = ({ currentUser, location }) => {
  const [options, setOptions] = useState({
    status: "pending",
    confirmGuidelines: false,
    confirmTnc: false,
    leaveReason: "Sick Leave",
    leaveFrom: "studies",
    firstName: "",
    lastName: "",
    dob: "",
    age: "",
    address: {
      country: "India",
    },
    mobile: "",
    email: "",
    reason: "",
    otherReason: "",
    reasonDescription: "",
    leaveDateFrom: "",
    leaveDateTo: "",
    orgAddress: "",
    orgName: "",
    idFront: "",
    idBack: "",
    optionalDoc: "",
    gender: "male",
    userId: currentUser.id,
    assigned: false,
    product: products[0],
    paymentStatus: "pending",
    doctorRemarks: [],
    additionalInfoRequired: false,
    promocode: "",
    isPrescriptionRequired: true,
    isEditRequired: false,
    isStudent: false,
    subType: location.state?.type || "",
    orgLocation: "india",
  });
  const history = useHistory();
  const [smallDevice, setSmallDevice] = useState(false);
  const [countChars, setCountChars] = useState(25);
  const [savingCertificate, setSavingCertificate] = useState(false);
  const [error, setError] = useState({
    message: "",
    key: "",
  });
  const [idFrontLoading, setIdFrontLoading] = useState(false);
  const [idBackLoading, setIdBackLoading] = useState(false);
  const [optionalDocLoading, setOptionalDocLoading] = useState(false);
  const [applyDiscount, setApplyDiscount] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [hidePrescriptionAlert, setHidePrescriptionAlert] = useState(true);

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  });

  const handleChange = (key, value, additionalField) => {
    if (key === "otherReason") {
      const max = 20;
      setCountChars(max - value.length);
    }
    if (additionalField) {
      setOptions({
        ...options,
        [key]: {
          ...options[key],
          [additionalField]: value,
        },
      });
    } else {
      setOptions({
        ...options,
        [key]: value,
      });
    }
    if (key === "product" && options.promocode) {
      verifyPromoCode(value);
    }
  };

  const uploadImage = async (file, key, event) => {
    if (!file) {
      toast.error("New image required");
      return null;
    }
    if (key === "idFront") {
      setIdFrontLoading(true);
    } else if (key === "idBack") {
      setIdBackLoading(true);
    } else {
      setOptionalDocLoading(true);
    }
    await uploadFile(file, "image", async (url, err) => {
      if (err) {
        alert(err);
        return null;
      }
      event.target.value = null;
      setOptions((prevState) => ({
        ...prevState,
        [key]: url,
      }));

      if (key === "idFront") {
        setIdFrontLoading(false);
      } else if (key === "idBack") {
        setIdBackLoading(false);
      } else {
        setOptionalDocLoading(false);
      }
    });
  };

  const deleteImage = async (url, key, event) => {
    if (window.confirm("Are you sure to remove image?")) {
      if (key === "idFront") {
        setIdFrontLoading(true);
      } else if (key === "idBack") {
        setIdBackLoading(true);
      } else {
        setOptionalDocLoading(true);
      }

      await removeFile(url);

      if (key === "idFront") {
        setIdFrontLoading(false);
      } else if (key === "idBack") {
        setIdBackLoading(false);
      } else {
        setOptionalDocLoading(false);
      }
      setOptions((prevState) => ({
        ...prevState,
        [key]: "",
      }));
    }
  };

  const redirectToGuildelines = () => {
    if (
      window.confirm("Your form data will be lost. Are you sure to redirect?")
    ) {
      history.push({
        pathname: Routes.APP_GUIDELINES_SLC,
        state: {
          options,
        },
      });
    }
  };

  const showErrors = () => {
    let error;
    if (!options.firstName) {
      error = {
        message: "First name is required",
        key: "firstName",
      };
    } else if (!options.lastName) {
      error = {
        message: "Last name is required",
        key: "lastName",
      };
    } else if (!options.dob) {
      error = {
        message: "Date of birth is required",
        key: "dob",
      };
    } else if (!options.age) {
      error = {
        message: "Age is required",
        key: "age",
      };
    } else if (!options.email) {
      error = {
        message: "Email is required",
        key: "email",
      };
    } else if (!options.mobile) {
      error = {
        message: "Mobile number is required",
        key: "mobile",
      };
    } else if (
      options.mobile &&
      options.orgLocation !== "abroad" &&
      options.mobile.length !== 12
    ) {
      error = {
        message: "Mobile number must contain 12-digits",
        key: "mobile",
      };
    } else if (!options.orgName) {
      error = {
        message: "Workplace name is required",
        key: "orgName",
      };
    } else if (!options.orgAddress) {
      error = {
        message: "Workplace address is required",
        key: "orgAddress",
      };
    } else if (!options.leaveDateFrom) {
      error = {
        message: "From date is required",
        key: "leaveDateFrom",
      };
    } else if (!options.leaveDateTo) {
      error = {
        message: "To date is required",
        key: "leaveDateTo",
      };
    } else if (!options.reason) {
      error = {
        message: "Reason is required",
        key: "reason",
      };
    } else if (options.reason === "Other" && !options.otherReason) {
      error = {
        message: "Other reason is required",
        key: "otherReason",
      };
    } else if (options.isStudent && !options.studentIdDoc) {
      error = {
        message: "Student ID is required",
        key: "studentIdDoc",
      };
    } else if (!options.product) {
      error = {
        message: "Choose a product",
        key: "product",
      };
    } else if (!options.confirmTnc) {
      error = {
        message: "Please confirm our Terms and Conditions",
        key: "confirmTnc",
      };
    } else if (!options.confirmGuidelines) {
      error = {
        message: "Please confirm our Guidelines",
        key: "confirmGuidelines",
      };
    }
    setError(error);
    return error;
  };

  const saveCertificate = async () => {
    const err = await showErrors();
    if (err && err.message) {
      toast.error(err.message);
      return;
    }
    setSavingCertificate(true);
    options.priority = options.product.priority;

    options.product = {
      ...options.product,
      price: parseInt(totalCost()),
    };

    const res = await add(Tables.MEDICAL_REQUESTS, options);
    setSavingCertificate(false);
    if (res.status === 200) {
      toast.success(
        "Your request has been saved. Complete the payment to proceed further."
      );
      history.push({
        pathname: Routes.APP_RECHARGE,
        state: {
          amount: options.product.price,
          requestId: res.data.id,
        },
      });
    }
  };

  const verifyPromoCode = async (val) => {
    const cc = await getQuery(
      firestore
        .collection(Tables.PROMOCODES)
        .where("code", "==", options.promocode)
        .get()
    );
    const pcode = cc[0];
    const unixDate = moment().unix() * 1000;
    if (
      pcode &&
      pcode.code === options.promocode &&
      unixDate < pcode.expiresOn
    ) {
      if (pcode.type === "limited" && pcode.count > 0) {
        // limited promocodes
        update(Tables.PROMOCODES, pcode.id, {
          count: pcode.count - 1,
        });
        let dp = options.product.price * (1 - pcode.discountPercent);
        if (val) {
          dp = val.price * (1 - pcode.discountPercent);
        }
        setApplyDiscount(pcode);
        setDiscountPrice(dp);
        setError({
          key: "",
          message: "",
        });
      } else if (pcode.type !== "limited") {
        // if type is influencer
        let dp = options.product.price * (1 - pcode.discountPercent);
        if (val) {
          dp = val.price * (1 - pcode.discountPercent);
        }
        setApplyDiscount(pcode);
        setDiscountPrice(dp);
        setError({
          key: "",
          message: "",
        });
      } else {
        setError({
          key: "promocode",
          message: "Code is invalid",
        });
      }
    } else {
      setError({
        key: "promocode",
        message: "Code is invalid",
      });
    }
  };

  const totalCost = () => {
    if (discountPrice) {
      let p = discountPrice;
      if (options.isPrescriptionRequired) {
        p += options.product.prescriptionPrice;
      } else {
        p = discountPrice;
      }
      return p.toFixed(2);
    } else if (options.isStudent) {
      let p = options.product.studentPrice;
      if (options.isPrescriptionRequired) {
        p += options.product.prescriptionPrice;
      } else {
        p = options.product.studentPrice;
      }
      return p.toFixed(2);
    } else if (options.orgLocation === "abroad") {
      let p = options.product.abroadPrice;
      if (options.isPrescriptionRequired) {
        p += options.product.prescriptionPrice;
      } else {
        p = options.product.abroadPrice;
      }
      return p.toFixed(2);
    } else {
      let p = options.product.price;
      if (options.isPrescriptionRequired) {
        p += options.product.prescriptionPrice;
      } else {
        p = options.product.price;
      }
      return p.toFixed(2);
    }
  };

  return (
    <>
      <HeaderComponent
        headerText={location.state?.headerText || "Medical Certificate"}
      />
      <div className="dashboard-content">
        <div className="container create-post-wrapper pt-5 mt-sm-5">
          <div className="card p-2 p-sm-4 my-sm-5">
            <div className="row">
              <div className="col-12 mt-2 mt-sm-0">
                <h6>Leave is from *</h6>
                <div className="d-flex flex-inline">
                  <div className="">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="leaveFrom"
                      id="leaveFrom-1"
                      onChange={(e) => handleChange("leaveFrom", "studies")}
                      checked={options.leaveFrom === "studies"}
                    />
                    <label className="form-check-label" htmlFor="leaveFrom-1">
                      Studies
                    </label>
                  </div>
                  <div className="pl-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="leaveFrom"
                      id="leaveFrom-2"
                      onChange={(e) => handleChange("leaveFrom", "work")}
                      checked={options.leaveFrom === "work"}
                    />
                    <label className="form-check-label" htmlFor="leaveFrom-2">
                      Work
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <h6>
                Full name of the person who requires leave * <br />
                <small className="text-secondary fs-10 d-none">
                  *As per Govt ID proof
                </small>
              </h6>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className={`form-control font-small ${
                      error?.key === "firstName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="First name"
                    onChange={(e) => handleChange("firstName", e.target.value)}
                  />
                  {error?.key === "firstName" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">First name</small>
                  )}
                </div>
                <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                  <input
                    type="text"
                    className={`form-control font-small ${
                      error?.key === "lastName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Last name"
                    onChange={(e) => handleChange("lastName", e.target.value)}
                  />
                  {error?.key === "lastName" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">Last name</small>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-6">
                <h6>Date of birth *</h6>
                <input
                  type="date"
                  className={`form-control ${
                    error?.key === "dob" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="dob"
                  onChange={(e) => handleChange("dob", e.target.value)}
                />
                {error?.key === "dob" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
              <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                <h6>Age *</h6>
                <input
                  type="number"
                  className={`form-control ${
                    error?.key === "age" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="Age"
                  onChange={(e) => handleChange("age", e.target.value)}
                />
                {error?.key === "age" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
            </div>
            <div className="mt-4">
              <h6>Gender *</h6>
              <select
                className="form-control font-small"
                aria-label=".form-select-sm"
                onChange={(e) => handleChange("gender", e.target.value)}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-6">
                <h6 htmlFor="form-question">Email *</h6>
                <input
                  type="email"
                  className={`form-control ${
                    error?.key === "email" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="Email"
                  onChange={(e) => handleChange("email", e.target.value)}
                />
                {error?.key === "email" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
              <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                <h6 htmlFor="form-question">Mobile *</h6>
                <input
                  type="number"
                  className={`form-control ${
                    error?.key === "mobile" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="917890789090"
                  onChange={(e) => handleChange("mobile", e.target.value)}
                />
                <small className="text-secondary fs-10">
                  e.g. 917890789078
                </small>
                {error?.key === "mobile" && (
                  <div className="text-danger fs-10">{error.message}</div>
                )}
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-6">
                <h6 htmlFor="form-question">Leave From *</h6>
                <input
                  type="date"
                  className={`form-control ${
                    error?.key === "leaveDateFrom" && "error-border"
                  }`}
                  onChange={(e) =>
                    handleChange("leaveDateFrom", e.target.value)
                  }
                  max={moment().format("DD/MM/YYYY")}
                />
                {error?.key === "leaveDateFrom" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
              <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                <h6 htmlFor="form-question">Leave To *</h6>
                <input
                  type="date"
                  className={`form-control ${
                    error?.key === "leaveDateTo" && "error-border"
                  }`}
                  onChange={(e) => handleChange("leaveDateTo", e.target.value)}
                  max={moment().format("DD/MM/YYYY")}
                />
                {error?.key === "leaveDateTo" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
            </div>
            {/* medical problem */}
            <div className="mt-4 pt-1">
              <h6>
                The main reason for the certificate *
                <div className="text-secondary fs-10 pt-2 fw-400">
                  This will be written on your certificate.
                </div>
              </h6>
              <div className="row">
                {reasons.map((reason, idx) => (
                  <div key={idx} className="col-12 col-sm-6 py-1">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="reason"
                      id={`reason-${idx}`}
                      onChange={(e) => handleChange("reason", reason)}
                      checked={options.reason === reason}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`reason-${idx}`}
                    >
                      {reason}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            {options.reason === "Other" && (
              <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                <input
                  type="text"
                  className={`form-control font-small ${
                    error?.key === "otherReason" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="eg. body pain, mild fever etc."
                  onChange={(e) => handleChange("otherReason", e.target.value)}
                  maxLength="25"
                />
                <small className="text-secondary fs-10">
                  {countChars} chars left
                </small>
              </div>
            )}
            {error?.key === "reason" && (
              <small className="text-danger fs-10">{error.message}</small>
            )}
            {error?.key === "otherReason" && (
              <small className="text-danger fs-10">{error.message}</small>
            )}
            <hr />
            <div className="text-secondary fs-10 fw-400 mt-1">
              No medical certificate is issued as "Whomsoever It May Concern" as
              the National Medical Council guidelines state that a medical
              certificate has to be addressed to an organisation
              (School/College/Company){" "}
            </div>
            <div className="mt-4">
              <h6>Organization Name *</h6>
              <input
                type="text"
                className={`form-control col-12 ${
                  error?.key === "orgName" && "error-border"
                }`}
                name="form-question"
                placeholder="Organization Name"
                onChange={(e) => handleChange("orgName", e.target.value)}
              />
              {error?.key === "orgName" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            <div className="mt-4">
              <h6>
                Organization Located in *
                <div className="text-secondary fs-10 fw-400 mt-1">
                  Organization address should match with its location{" "}
                </div>
              </h6>
              <select
                className="form-control font-small"
                aria-label=".form-select-sm"
                onChange={(e) => handleChange("orgLocation", e.target.value)}
              >
                <option value="india" defaultValue>
                  India
                </option>
                <option value="abroad">Outside India</option>
              </select>
            </div>
            <div className="mt-4">
              <h6>Organization Address *</h6>
              <textarea
                className={`form-control fs-12 ${
                  error?.key === "orgAddress" && "error-border"
                }`}
                name="form-question"
                placeholder="Complete Organization Address"
                onChange={(e) => handleChange("orgAddress", e.target.value)}
              />
              {error?.key === "orgAddress" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>

            <div className="mt-4 d-none">
              <h6>
                Please describe the timeline of your symptoms, and the details
                of your symptoms
              </h6>
              <textarea
                className={`form-control fs-12 ${
                  error?.key === "reasonDescription" && "error-border"
                }`}
                name="form-question"
                rows="4"
                placeholder="Type here."
                onChange={(e) =>
                  handleChange("reasonDescription", e.target.value)
                }
              />
              {error?.key === "reasonDescription" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            {/* documents */}
            <div className="mt-4 d-none">
              <h6>
                Proof of Identification *
                <div className="text-secondary fs-10 pt-2 fw-400">
                  Only PNG/JPEG formats supported like Driving license, Aadhar,
                  PAN, Passport etc.
                </div>
              </h6>
              <div className="row container text-center pr-0">
                <div className="col-12 col-sm-6 image-label">
                  {idFrontLoading && <i className="fa fa-spinner fa-spin" />}
                  <div
                    className={`${
                      (options.idFront || idFrontLoading) && "d-none"
                    }`}
                  >
                    <label htmlFor="idFront" className="pointer">
                      Front{" "}
                    </label>
                    <input
                      type="file"
                      className={`form-control-plaintext d-none ${
                        error?.key === "idFront" && "error-border"
                      }`}
                      id="idFront"
                      onChange={(e) =>
                        uploadImage(e.target.files[0], "idFront", e)
                      }
                      accept="image/*"
                    />
                  </div>
                  {options.idFront && !idFrontLoading && (
                    <div>
                      <button
                        className="btn btn-violet btn-sm"
                        onClick={(e) =>
                          deleteImage(options.idFront, "idFront", e)
                        }
                      >
                        Delete Image
                      </button>
                      <br />
                      <img
                        src={options.idFront}
                        className="camera-icon"
                        alt="Image"
                        width="100px"
                        height="100px"
                      />
                    </div>
                  )}
                </div>
                <div className="col-12 col-sm-6 image-label">
                  {idBackLoading && <i className="fa fa-spinner fa-spin" />}
                  <div
                    className={`${
                      (options.idBack || idBackLoading) && "d-none"
                    }`}
                  >
                    <label htmlFor="idBack" className="pointer">
                      Back{" "}
                    </label>
                    <input
                      type="file"
                      className={`form-control-plaintext d-none ${
                        error?.key === "idFront" && "error-border"
                      }`}
                      id="idBack"
                      onChange={(e) =>
                        uploadImage(e.target.files[0], "idBack", e)
                      }
                      accept="image/*"
                    />
                  </div>
                  {options.idBack && !idBackLoading && (
                    <div>
                      <button
                        className="btn btn-violet btn-sm"
                        onClick={(e) =>
                          deleteImage(options.idBack, "idBack", e)
                        }
                      >
                        Delete Image
                      </button>{" "}
                      <br />
                      <img
                        src={options.idBack}
                        className="camera-icon"
                        alt="Image"
                        width="100px"
                        height="100px"
                      />
                    </div>
                  )}
                </div>
              </div>
              {error?.key === "idFront" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
              {error?.key === "idBack" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            <div
              className={`mt-4 pt-1 d-none ${
                (!options.age || options.age > 18) && "d-none"
              }`}
            >
              <h6>
                Are you a student?
                <div className="text-secondary fs-10 pt-2 fw-400 d-none">
                  Get student discount with student ID proof.
                </div>
              </h6>
              <div className="col-12 py-1 ml-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="isStudent"
                  id="isStudent"
                  onChange={(e) =>
                    handleChange("isStudent", !options.isStudent)
                  }
                />
                <label className="form-check-label" htmlFor="isStudent">
                  Yes, I'm student. Give me student discount.
                </label>
              </div>
            </div>
            {options.isStudent && (
              <div className="mt-4">
                <h6>
                  Student ID upload *
                  <div className="text-secondary fs-10 pt-2 fw-400">
                    Our admin team will verify all student requests manually.
                  </div>
                </h6>
                <div className="text-center image-label">
                  {optionalDocLoading && (
                    <i className="fa fa-spinner fa-spin" />
                  )}
                  <div
                    className={`${
                      (options.studentIdDoc || optionalDocLoading) && "d-none"
                    }`}
                  >
                    <label htmlFor="studentIdDoc" className="pointer">
                      Browse File{" "}
                    </label>
                    <input
                      type="file"
                      className="form-control-plaintext d-none"
                      id="studentIdDoc"
                      onChange={(e) =>
                        uploadImage(e.target.files[0], "studentIdDoc", e)
                      }
                      accept="application/pdf,image/*"
                    />
                  </div>
                  {options.studentIdDoc && !optionalDocLoading && (
                    <div>
                      <button
                        className="btn btn-violet btn-sm"
                        onClick={(e) =>
                          deleteImage(options.studentIdDoc, "studentIdDoc", e)
                        }
                      >
                        Delete File
                      </button>{" "}
                      <br />
                      <object
                        data={options.studentIdDoc}
                        className="camera-icon"
                        width="100%"
                        height="150px"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="text-center mt-5 d-none">
              <h5>Workplace details</h5>
            </div>
            <small className="text-center text-secondary fs-10 d-none">
              No medical certificate is issued as "Whomsoever It May Concern" as
              the National Medical Council guidelines state that a medical
              certificate has to be addressed to an organisation
              (School/College/Company){" "}
            </small>
            <hr className="d-none" />
            <div className="mt-3 d-none">
              <h6>Workplace name *</h6>
              <input
                type="text"
                className={`form-control col-12 ${
                  error?.key === "orgName" && "error-border"
                }`}
                name="form-question"
                placeholder="Workplace Name"
                onChange={(e) => handleChange("orgName", e.target.value)}
              />
              {error?.key === "orgName" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            <div className="mt-4 d-none">
              <h6>Workplace Address *</h6>
              <textarea
                className={`form-control fs-12 ${
                  error?.key === "orgAddress" && "error-border"
                }`}
                name="form-question"
                placeholder="Complete Workplace Address"
                onChange={(e) => handleChange("orgAddress", e.target.value)}
              />
              {error?.key === "orgAddress" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            <div className="text-center mt-5 d-none">
              <h5>
                Dates of the certificate <br />
                <small className="text-secondary fs-10">
                  Future date certificates will not be issued.{" "}
                </small>
              </h5>
            </div>
            <hr className="d-none" />
            <div className="row mt-4 d-none">
              <div className="col-12 col-sm-6">
                <h6 htmlFor="form-question">Leave Date From *</h6>
                <input
                  type="date"
                  className={`form-control ${
                    error?.key === "leaveDateFrom" && "error-border"
                  }`}
                  onChange={(e) =>
                    handleChange("leaveDateFrom", e.target.value)
                  }
                  max={moment().format("DD/MM/YYYY")}
                />
                {error?.key === "leaveDateFrom" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
              <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                <h6 htmlFor="form-question">Leave Date To *</h6>
                <input
                  type="date"
                  className={`form-control ${
                    error?.key === "leaveDateTo" && "error-border"
                  }`}
                  onChange={(e) => handleChange("leaveDateTo", e.target.value)}
                  max={moment().format("DD/MM/YYYY")}
                />
                {error?.key === "leaveDateTo" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
              <small className="text-secondary fs-10 p-3 d-none">
                *Above 7 days of leave request, a prescription or reports is
                mandatory for us to issue a medical certificate. Issuance of the
                medical certificate and duration is based on the discretion of
                the consulting doctor.
              </small>
            </div>
            <hr className="mt-4" />
            {/* products */}
            <div className="text-orange-600 fw-500 mt-2 text-center">
              Summer Offer: 25% discount on all products
            </div>
            <div className="mt-3">
              <h6>My products *</h6>
              <div className="row">
                {products.map(
                  (product, idx) =>
                    product.display && (
                      <div key={idx} className="col-12 my-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="product"
                          id={`product-${idx}`}
                          onChange={(e) => handleChange("product", product)}
                          checked={options.product.name === product.name}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`product-${idx}`}
                        >
                          <div className="media">
                            <div className="media-body">
                              <div className="fw-500">
                                {product.name} {smallDevice && <div></div>}
                                <div
                                  className={
                                    smallDevice ? "my-2" : "pull-right"
                                  }
                                >
                                  {options.isStudent &&
                                    product.studentPrice.toFixed(2)}
                                  {options.orgLocation === "abroad" &&
                                    product.abroadPrice.toFixed(2)}
                                  {options.orgLocation !== "abroad" &&
                                    !options.isStudent &&
                                    product.price.toFixed(2)}{" "}
                                  {currency} &nbsp;
                                  <strike className="text-orange-600 fw-500 mt-2 fs-12">
                                    {options.orgLocation === "abroad"
                                      ? product.abroadTotalPrice.toFixed(2)
                                      : product.totalPrice.toFixed(2)}{" "}
                                    {currency}
                                  </strike>
                                </div>
                              </div>
                              <div className="mt-1 fs-12">
                                {product.description}
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    )
                )}
              </div>
              <div className="mt-4 ml-4">
                <div className="form-question fs-12">Have a Referral Code?</div>
                <div className="input-group col-12 col-sm-6 pl-0">
                  <input
                    type="text"
                    className={`form-control fs-12 ${
                      error?.key === "promocode" && "error-border"
                    }`}
                    placeholder="Referral code"
                    aria-label="Referral code"
                    aria-describedby="promo-code"
                    onChange={(e) => handleChange("promocode", e.target.value)}
                    disabled={discountPrice}
                  />
                  <div
                    className="input-group-append"
                    onClick={(e) => verifyPromoCode()}
                  >
                    <span
                      className="btn btn-violet btn-sm px-3"
                      id="promo-code"
                      disabled={discountPrice}
                    >
                      {applyDiscount ? "Applied!" : "Apply"}
                    </span>
                  </div>
                </div>
                <small className="text-secondary fs-10">e.g. MEDOC_20D</small>
                {error?.key === "promocode" && (
                  <div className="text-danger fs-10">{error.message}</div>
                )}
              </div>
              <div>
                <div className="pt-4 text-center">
                  <div
                    className={`alert alert-info fs-12 fade show ${
                      hidePrescriptionAlert && "d-none"
                    }`}
                    role="alert"
                  >
                    Some workplaces require prescription in addition to a sick
                    leave certificate. We recommend that you include
                    prescription if you are unsure about your workplace
                    policies.
                  </div>
                  <input
                    className="form-check-input ml-1 mt-2"
                    type="checkbox"
                    name="isPrescriptionRequired"
                    id="isPrescriptionRequired"
                    onChange={(e) =>
                      handleChange(
                        "isPrescriptionRequired",
                        !options.isPrescriptionRequired
                      )
                    }
                    checked={options.isPrescriptionRequired}
                  />
                  <label
                    className={`form-check-label ml-4 mt-1 text-justify ${
                      error?.key === "isPrescriptionRequired" && "error-color"
                    }`}
                    htmlFor="isPrescriptionRequired"
                  >
                    <small className="fw-500">
                      Get prescription for {options.product.prescriptionPrice}
                      .00 INR/-
                    </small>
                  </label>{" "}
                  <i
                    className="fa fa-info-circle pointer text-violet"
                    onClick={(e) =>
                      setHidePrescriptionAlert(!hidePrescriptionAlert)
                    }
                  />
                </div>
              </div>
              <div className="mt-2 text-center fs-16 fw-500">
                Total cost:{" "}
                <span className="text-violet">
                  {totalCost()}
                  {currency}
                  {discountPrice && options.product.price && (
                    <strike className="text-orange-600 pl-2">
                      {options.product.price.toFixed(2)} {currency}
                    </strike>
                  )}
                </span>
              </div>
              {discountPrice && (
                <div className="text-orange-600 text-center pt-3 fs-12">
                  Hurray! You saved extra {applyDiscount.discountPercent * 100}%
                </div>
              )}
              <hr />
              <div className="tnc">
                <div className="pt-2">
                  <input
                    className="form-check-input ml-1 mt-2"
                    type="checkbox"
                    name="confirmGuidelines"
                    id="confirmGuidelines"
                    onChange={(e) => handleChange("confirmGuidelines", true)}
                    checked={options.confirmGuidelines}
                  />
                  <label
                    className={`form-check-label ml-4 text-justify ${
                      error?.key === "confirmGuidelines" && "error-color"
                    }`}
                    htmlFor="confirmGuidelines"
                  >
                    I adhere to all our{" "}
                    <span
                      className="text-violet px-0 mx-0 fw-500"
                      onClick={redirectToGuildelines}
                    >
                      medoc guidelines.
                    </span>
                  </label>
                </div>
              </div>
              <div className="tnc">
                <div className="pt-2">
                  <input
                    className="form-check-input ml-1"
                    type="checkbox"
                    name="confirmTnc"
                    id="confirmTnc"
                    onChange={(e) => handleChange("confirmTnc", true)}
                    checked={options.confirmTnc}
                  />
                  <label
                    className={`form-check-label ml-4 text-justify ${
                      error?.key === "confirmGuidelines" && "error-color"
                    }`}
                    htmlFor="confirmTnc"
                  >
                    I confirm that I am not seriously unwell and I do NOT have
                    any of the following symptoms: chest pain, shortness of
                    breath, unable to swallow fluids or saliva, weakness or
                    numbness down one side, and slurred speech. I confirm I do
                    not think I need to see a General Practitioner. If symptoms
                    are related to a COVID vaccine, I will consult my General
                    Practitioner or the medical professional who provided the
                    immunization. I agree that the issuance of a medical
                    certificate and its duration is based on the discretion of
                    the consulting doctor. I confirm that I am not using the
                    certificate for any medico-legal purposes. In case of
                    non-issuance of the certificate due to any reason, a service
                    charge of INR {totalCost() >= 1000 ? 500 : 250}/- will be
                    deducted while refunding the amount. The refund will be done
                    online and usually takes 7-10 working days. I also agree to
                    the{" "}
                    <Link
                      to="/rp"
                      target="_blank"
                      className="text-violet fw-500"
                    >
                      Refund and cancellation policy
                    </Link>
                    replacement for a doctor's visit. I have read, understood,
                    and agree with the information in the terms of service and
                    consent to the use of information in accordance with the
                    privacy policy.
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            className="complete-payment btn btn-violet text-center"
            onClick={saveCertificate}
          >
            {savingCertificate ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <span>Complete Payment</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePostComponent;
