import React from "react";
import { Link } from "react-router-dom";

import { Routes } from "constants/index";
import "./style.css";

const StaticHeaderComponent = ({ routes, bottomFooter }) => {
  return (
    <div className="static-header">
      <Link
        to={Routes.LOGIN}
        className={
          bottomFooter === "false" ? "d-none" : "add-on-text fixed-bottom"
        }
      >
        <small>&#128994;</small> Doctor Buddies are online
      </Link>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top">
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="home-page-title fw-600">
              medoc <small>doctor</small>
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto align-items-center">
              <li className="nav-item">
                <div className="nav-link fw-500">
                  <a
                    href={Routes.ABOUT_US}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-violet"
                  >
                    About Us
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link fw-500">
                  <a
                    href={Routes.CONTACT_US}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-violet"
                  >
                    Contact Us
                  </a>
                </div>
              </li>
              <li className="nav-item d-none">
                <div className="nav-link fw-500">
                  <a href="/home#faq" className="text-violet">
                    FAQ
                  </a>
                </div>
              </li>
              <li className="nav-item d-none">
                <div className="nav-link fw-500">
                  <Link to="/employer_verify" className="text-violet">
                    Employer Verify
                  </Link>
                </div>
              </li>
              <li className="nav-item d-none">
                <div className="nav-link fw-500">
                  <Link to="/login" className="text-violet">
                    Login
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link">
                  <Link to={Routes.LOGIN} className="btn btn-violet py-2 px-4">
                    Consult Now
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default StaticHeaderComponent;
