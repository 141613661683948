import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { LoadingComponent, HeaderComponent } from "components";
import { Routes } from "constants/index";
import { post } from "services";

const RechargeSuccessComponent = (props) => {
  const { requestId, order, response } = props?.location?.state;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== "development") {
      /*
      window.gtag("event", "conversion", {
        send_to: "AW-11110050363/e8TFCP79qZEYELvU17Ep",
        transaction_id: response.razorpay_payment_id,
      });
      <!-- Event snippet for Purchase (11/03/2023) (/recharge_success) conversion page -->
      window.gtag('event', 'conversion', {
          'send_to': 'AW-11110050363/zLcaCKPOzJAYELvU17Ep',
          'transaction_id': ''
      });
      */
      window.gtag("event", "conversion", {
        send_to: "AW-11027898121/TJgaCNW4tIgYEIm-wYop",
        value: 100,
        currency: order.currency,
        transaction_id: response.razorpay_payment_id,
      });
    }
  }, []);

  const completeTransaction = async () => {
    setLoading(true);
    await post("/transaction/recharge", {
      amount: order.amount,
      paymentIntentId: response.razorpay_payment_id,
      orderId: response.razorpay_order_id,
      currency: order.currency,
      requestId: requestId,
      signature: response.razorpay_signature,
    });
    history.push(`${Routes.APP_POSTS}/${requestId}`);
    setLoading(false);
  };

  return (
    requestId && (
      <>
        <HeaderComponent />
        {loading ? (
          <div>
            <LoadingComponent />
            <div className="text-secondary text-center mt-5 pt-5">
              Please donot refresh this page while we process your request!
            </div>
          </div>
        ) : (
          <div className="dashboard-content pt-5 mt-5 text-center">
            <h3>Your payment is successful</h3>
            <div className="mb-4">
              Complete your next steps to submit your request
            </div>
            <div className="btn btn-violet" onClick={completeTransaction}>
              Proceed
            </div>
          </div>
        )}
      </>
    )
  );
};

export default RechargeSuccessComponent;
