import React, { useState } from "react";
import moment from "moment";

import { getQuery, firestore } from "firebase_config";
import { Tables } from "constants/index";

const AddPaymentDetails = ({ userDetails, handleChange, error }) => {
  const [newPayment, setNewPayment] = useState({
    totalAmount: 0,
    deductions: 0,
    netAmount: 0,
    payLength: 0,
    cpayLength: 0,
    startDate: "",
    endDate: "",
  });

  const calculateTotalAmount = async () => {
    const startDate = moment(newPayment.startDate).unix() * 1000;
    const endDate = moment(newPayment.endDate).unix() * 1000;
    const requests = await getQuery(
      firestore
        .collection(Tables.MEDICAL_REQUESTS)
        .where("updatedAt", ">", startDate)
        .where("updatedAt", "<=", endDate)
        .where("doctorId", "==", userDetails.id)
        .get()
    );

    const pay = requests.filter(
      (req) => !req.isPrescriptionRequired && req.status !== "pending"
    ).length;
    const cpay = requests.filter(
      (req) => req.isPrescriptionRequired && req.status !== "pending"
    ).length;

    const tp = userDetails.pay * pay + userDetails.payWithPrescription * cpay;
    setNewPayment({
      totalAmount: tp,
      deductions: tp * 0.1,
      netAmount: tp - tp * 0.1,
      payLength: pay,
      cpayLength: cpay,
    });
  };

  return (
    <div>
      <h6>Pay monthly salary</h6>
      <div className="col-12 col-sm-6 mt-2">
        <h6 htmlFor="form-question">Day *</h6>
        <select
          className="form-control font-small"
          aria-label=".form-select-sm"
          onChange={(e) =>
            handleChange("newPayment", e.target.value, "paymentDate")
          }
        >
          <option value=""></option>
          {[
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
          ].map((i) => (
            <option value={i} key={i}>
              {i}
            </option>
          ))}
        </select>
        {error?.key === "totalAmount" && (
          <div className="text-danger fs-10">{error.message}</div>
        )}
      </div>
      <div className="col-12 col-sm-6 mt-2">
        <h6 htmlFor="form-question">Month *</h6>
        <select
          className="form-control font-small"
          aria-label=".form-select-sm"
          onChange={(e) =>
            handleChange("newPayment", e.target.value, "paymentMonth")
          }
        >
          <option value=""></option>
          <option value="january">January</option>
          <option value="february">February</option>
          <option value="march">March</option>
          <option value="april">April</option>
          <option value="may">May</option>
          <option value="june">June</option>
          <option value="july">July</option>
          <option value="august">August</option>
          <option value="september">September</option>
          <option value="october">October</option>
          <option value="november">November</option>
          <option value="december">December</option>
        </select>
        {error?.key === "totalAmount" && (
          <div className="text-danger fs-10">{error.message}</div>
        )}
      </div>
      <div className="col-12 col-sm-6 mt-2">
        <h6 htmlFor="form-question">Year *</h6>
        <select
          className="form-control font-small"
          aria-label=".form-select-sm"
          onChange={(e) =>
            handleChange("newPayment", e.target.value, "paymentYear")
          }
        >
          <option value=""></option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
        </select>
        {error?.key === "totalAmount" && (
          <div className="text-danger fs-10">{error.message}</div>
        )}
      </div>
      <div className="col-12 col-sm-6 mt-2">
        <h6 htmlFor="form-question">Total Amount * </h6>
        <input
          type="number"
          className={`form-control ${
            error?.key === "totalAmount" && "error-border"
          }`}
          name="form-question"
          value={userDetails.totalAmount}
          onChange={(e) =>
            handleChange("newPayment", +e.target.value, "totalAmount")
          }
        />
        {error?.key === "totalAmount" && (
          <div className="text-danger fs-10">{error.message}</div>
        )}
      </div>
      <div className="col-12 col-sm-6 mt-4">
        <h6 htmlFor="form-question">Deductions *</h6>
        <input
          type="number"
          className={`form-control ${
            error?.key === "deductions" && "error-border"
          }`}
          name="form-question"
          value={userDetails.deductions}
          onChange={(e) =>
            handleChange("newPayment", +e.target.value, "deductions")
          }
        />
        {error?.key === "deductions" && (
          <div className="text-danger fs-10">{error.message}</div>
        )}
      </div>
      <div className="col-12 col-sm-6 mt-4">
        <h6 htmlFor="form-question">Net Amount *</h6>
        <input
          type="number"
          className={`form-control ${
            error?.key === "netAmount" && "error-border"
          }`}
          name="form-question"
          value={userDetails.netAmount}
          onChange={(e) =>
            handleChange("newPayment", +e.target.value, "netAmount")
          }
        />
        {error?.key === "netAmount" && (
          <div className="text-danger fs-10">{error.message}</div>
        )}
      </div>
      <div>
        <div className="row mt-4">
          <div className="col-12 col-sm-6">
            <h6>Start Date *</h6>
            <input
              type="date"
              className={`form-control ${
                error?.key === "startDate" && "error-border"
              }`}
              name="form-question"
              onChange={(e) =>
                setNewPayment({ ...newPayment, startDate: e.target.value })
              }
            />
            {error?.key === "startDate" && (
              <small className="text-danger fs-10">{error.message}</small>
            )}
          </div>
          <div className="col-12 col-sm-6">
            <h6>End Date *</h6>
            <input
              type="date"
              className={`form-control ${
                error?.key === "dob" && "error-border"
              }`}
              name="form-question"
              onChange={(e) =>
                setNewPayment({ ...newPayment, endDate: e.target.value })
              }
            />
            {error?.key === "endDate" && (
              <small className="text-danger fs-10">{error.message}</small>
            )}
          </div>
        </div>
        <button className="btn btn-link" onClick={calculateTotalAmount}>
          Calculate
        </button>
        <div>
          Total Amount: {newPayment.totalAmount} <br />
          Deductions: {newPayment.deductions} <br />
          Net Amount: {newPayment.netAmount} <br />
          Normal consultations: {newPayment.payLength} <br />
          Prescription consultations: {newPayment.cpayLength}
        </div>
      </div>
    </div>
  );
};

export default AddPaymentDetails;
