import React, { useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import "./style.css";

import { signup, add, googleSignup } from "firebase_config";
import { StaticHeaderComponent } from "components";
import { validateEmail, convertToSnakeCase } from "helpers";
import { Tables, Routes } from "constants/index";
import { post } from "services";

const SignupComponent = (props) => {
  const [btnSave, setBtnSave] = useState("Submit");
  const [error, setErrors] = useState("");
  const [eyeIcon, setEyeIcon] = useState("fa-eye");
  const [options, setOptions] = useState({
    username: "",
    displayName: "",
    email: "",
    password: "",
    tnc: false,
    emailUpdates: true,
  });
  const history = useHistory();
  const inviteeUsername = props.match.params.username;

  const handleChange = (key, value) => {
    setOptions((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleForm = async (e) => {
    setErrors("");
    e.preventDefault();
    if (!options.displayName || !options.displayName.trim()) {
      setErrors("Full Name is mandatory");
      return null;
    }
    if (options.displayName && !options.displayName.match("^[a-z A-Z]+$")) {
      setErrors("Only alphabets are accepted");
      return null;
    }

    const data = {
      username: options.displayName.toLowerCase().replace(/ /g, "-"),
      displayName: options.displayName,
    };
    if (!options.qualification || !options.qualification.trim()) {
      setErrors("Qualification is mandatory");
      return null;
    }
    if (!options.nmcNumber || !options.nmcNumber.trim()) {
      setErrors("NMC Number is mandatory");
      return null;
    }
    if (!options.mobile || !options.mobile.trim()) {
      setErrors("Mobile Number is mandatory");
      return null;
    }
    if (options.mobile && options.mobile.length !== 10) {
      setErrors("10-digits Mobile Number is required");
      return null;
    }
    if (!options.email || !options.email.trim()) {
      setErrors("Email is mandatory");
      return null;
    }
    if (options.email && !validateEmail(options.email)) {
      setErrors("Enter a valid email");
      return null;
    }
    if (!options.password || !options.password.trim()) {
      setErrors("Password is mandatory");
      return null;
    }
    if (options.password && options.password.trim().length < 12) {
      setErrors("Password must have atleast 12 characters");
      return null;
    }
    if (!options.cpassword || !options.cpassword.trim()) {
      setErrors("Confirm Password is mandatory");
      return null;
    }
    if (
      options.cpassword &&
      options.cpassword.trim() !== options.password.trim()
    ) {
      setErrors("Confirm Password mismatch");
      return null;
    }
    if (!options.tnc) {
      setErrors("Agree to our Terms and conditions");
      return null;
    }
    setBtnSave("Submitting...");
    const res = await signup({
      email: options.email,
      password: options.password,
      data,
    });
    if (res && res.status !== 200) {
      setErrors(res.message);
      setBtnSave("Submit");
      return null;
    }

    // delete options.emailUpdates;
    // delete options.tnc;
    // delete options.password;
    // delete options.cpassword;

    const createDbUser = await add(
      "users",
      Object.assign(options, {
        email: options.email.toLowerCase().trim(),
        mobile: "91" + options.mobile,
        username: data.username,
        displayName: data.displayName,
        permissions: {
          tnc: options.tnc,
          recordPageVisits: true,
          locationAccess: true,
          emailUpdates: options.emailUpdates,
        },
        photoURL: null,
        depositAmount: 0,
        withdrawAmount: 0,
        roles: ["levelOne"],
        emailVerified: true,
        verifyEmailSentTime: new Date(),
      })
    );

    if (inviteeUsername) {
      /* Invited by a user */
      await add("referals", {
        username: data.username,
        invitedBy: inviteeUsername,
      });
    }
    setBtnSave("Submit");
    if (createDbUser.status !== 200) {
      setErrors(createDbUser.message);
      return null;
    } else {
      post("/send_email", {
        sender: "naga",
        templateName: "welcome_new_user.html",
        email: options.email,
        subject: "Welcome to Medoc",
        displayName: data.displayName,
      });
    }
    history.replace("/app/posts");
  };

  const showPassword = (key) => {
    const input = document.getElementById(key);
    if (input.type === "password") {
      setEyeIcon("fa-eye-slash");
      input.type = "text";
    } else {
      setEyeIcon("fa-eye");
      input.type = "password";
    }
  };

  const handleGoogleSignup = async () => {
    const res = await googleSignup();

    if (res?.data?.additionalUserInfo?.isNewUser) {
      const userData = {
        email: res.data.email,
        username: convertToSnakeCase(res.data.displayName),
        displayName: res.data.displayName,
        permissions: {
          tnc: true,
          recordPageVisits: true,
          locationAccess: true,
          emailUpdates: true,
        },
        photoURL: null,
        depositAmount: 0,
        withdrawAmount: 0,
        roles: ["user"],
        emailVerified: true,
      };
      const createDbUser = await add(Tables.USERS, userData);

      if (inviteeUsername) {
        /* Invited by a user */
        await add(Tables.REFERRALS, {
          username: userData.username,
          invitedBy: inviteeUsername,
        });
      }
      setBtnSave("Submit");
      if (createDbUser.status !== 200) {
        setErrors(createDbUser.message);
        return null;
      } else {
        post("/send_email", {
          sender: "naga",
          templateName: "welcome_new_user.html",
          email: res.data.email,
          subject: "Welcome to Medoc",
          displayName: res.data.displayName,
        });
      }
    }
    history.replace("/");
  };

  return (
    <div className="signup-page" id="signup">
      <StaticHeaderComponent bottomFooter="false" />
      <div className="container login-form">
        <div className="d-flex justify-content-center my-5 py-5">
          <div className="col-12 order-1 order-md-2 col-sm-6 right-block">
            <div className="body">
              <p className="h6 mt-5 mb-3">Sign up</p>
              <p className="text-secondary fs-12">
                All details must be entered as per Indian Medical Council
                Registration. You can't change these details after sign up.
              </p>
              {error && (
                <div className="text-danger text-center my-3 fw-500">
                  {error}
                </div>
              )}
              <div className="input-group mb-2">
                <input
                  value={options.displayName}
                  onChange={(e) => handleChange("displayName", e.target.value)}
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Full Name"
                  autoFocus={true}
                  aria-label="name"
                />
              </div>
              <div className="input-group mb-2">
                <input
                  value={options.qualification}
                  onChange={(e) =>
                    handleChange("qualification", e.target.value)
                  }
                  name="qualification"
                  type="text"
                  className="form-control"
                  placeholder="Qualification"
                  aria-label="qualification"
                />
              </div>
              <div className="input-group mb-2">
                <input
                  value={options.nmcNumber}
                  onChange={(e) => handleChange("nmcNumber", e.target.value)}
                  name="nmcNumber"
                  type="text"
                  className="form-control"
                  placeholder="NMC Number"
                  aria-label="nmcNumber"
                />
              </div>
              <div className="input-group mb-2">
                <input
                  value={options.mobile}
                  onChange={(e) => handleChange("mobile", e.target.value)}
                  name="mobile"
                  type="number"
                  className="form-control"
                  placeholder="Mobile"
                  aria-label="mobile"
                />
              </div>
              <div className="input-group mb-2">
                <input
                  value={options.email}
                  onChange={(e) =>
                    handleChange("email", e.target.value.toLowerCase().trim())
                  }
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  aria-label="email"
                />
              </div>
              <div className="input-group mb-2">
                <input
                  onChange={(e) => handleChange("password", e.target.value)}
                  name="password"
                  value={options.password}
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Password (atleast 12 letters required)"
                  aria-label="password"
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text append"
                    id="basic-addon3"
                    onClick={(e) => showPassword("password")}
                  >
                    {eyeIcon === "fa-eye" ? (
                      <img
                        className="show-password"
                        src={require("assets/svgs/Eye.svg").default}
                        alt="Eye"
                      />
                    ) : (
                      <img
                        className="show-password"
                        src={require("assets/svgs/EyeOpen.svg").default}
                        alt="EyeOpen"
                      />
                    )}
                  </span>
                </div>
              </div>
              <div className="input-group mb-2">
                <input
                  onChange={(e) => handleChange("cpassword", e.target.value)}
                  name="cpassword"
                  value={options.cpassword}
                  type="password"
                  className="form-control"
                  id="cpassword"
                  placeholder="Confirm Password"
                  aria-label="cpassword"
                />
              </div>
              <div className="options pt-2">
                <div className="d-flex align-items-center form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="tnc"
                    name="tnc"
                    onChange={(e) => handleChange("tnc", !options.tnc)}
                    checked={options.tnc}
                  />
                  <label className="form-check-label" htmlFor="tnc">
                    All the above details are correct as per NMC & IMC. I accept
                    our{" "}
                    <a
                      href={Routes.TNC}
                      className="text-violet"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of service
                    </a>{" "}
                    and{" "}
                    <a
                      href={Routes.PRIVACY_POLICY}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-violet"
                    >
                      Privacy policy
                    </a>
                    .
                  </label>
                </div>
                <div className="d-flex form-check mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="emailUpdates"
                    name="emailUpdates"
                    onChange={(e) =>
                      handleChange("emailUpdates", !options.emailUpdates)
                    }
                    checked={options.emailUpdates}
                  />
                  <label className="form-check-label" htmlFor="emailUpdates">
                    I'd like to be notified by email.
                  </label>
                </div>
              </div>
              <div>
                <button
                  className="btn login-btn col-12"
                  disabled={btnSave !== "Submit"}
                  onClick={(e) => handleForm(e)}
                >
                  {btnSave}
                </button>
                <br />
                <Link to="/login" className="text-violet">
                  Already registered? Log in
                </Link>
                <br />
                <div className="d-none">
                  <div className="mt-4 mb-3 separator">OR</div>
                  <button
                    className="btn google-login-btn col-12 align-items-center"
                    onClick={handleGoogleSignup}
                  >
                    <i className="fa fa-google"></i> &nbsp;{" "}
                    <span className="fw-500">Signup with Google</span>
                  </button>
                  <div className="caption-text font-small fw-500 d-none">
                    By Signing up, you are agreeing to our
                    <a
                      href="/tnc"
                      className="text-violet pl-1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of service
                    </a>
                    , and our{" "}
                    <a
                      href="/privacy_policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-violet"
                    >
                      Privacy policy
                    </a>
                    .
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SignupComponent);
