import React from "react";
import { Link } from "react-router-dom";

import { HeaderComponent } from "components";
import { Routes } from "constants/index";

const AdminHomeComponent = (props) => {
  const isAdmin = props.currentUser.roles.find((role) => role === "admin");
  return (
    <>
      <HeaderComponent />
      {isAdmin && (
        <div className="row pt-5 mt-5 container">
          <div className="col-12 col-sm-6 col-md-4 product-card">
            <div className="card mx-2 mb-4 mx-sm-0">
              <Link
                to={Routes.APP_UPDATE_MEDICAL_REQUEST}
                className="h5 text-violet fw-600"
              >
                Update Medical Requests
              </Link>{" "}
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 product-card">
            <div className="card mx-2 mb-4 mx-sm-0">
              <Link
                to={Routes.APP_UPDATE_USER}
                className="h5 text-violet fw-600"
              >
                Update Users
              </Link>{" "}
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 product-card">
            <div className="card mx-2 mb-4 mx-sm-0">
              <Link
                to={Routes.APP_GET_MEDICAL_REQUEST}
                className="h5 text-violet fw-600"
              >
                Get Medical Requests
              </Link>{" "}
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 product-card">
            <div className="card mx-2 mb-4 mx-sm-0">
              <Link
                to={Routes.APP_GET_CLIENT_REQUEST}
                className="h5 text-violet fw-600"
              >
                Client Requests
              </Link>{" "}
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 product-card">
            <div className="card mx-2 mb-4 mx-sm-0">
              <Link
                to={Routes.APP_GET_DOCTOR_REQUEST}
                className="h5 text-violet fw-600"
              >
                Doctor Requests
              </Link>{" "}
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 product-card">
            <div className="card mx-2 mb-4 mx-sm-0">
              <Link
                to={Routes.APP_GET_ORGANISATION_REQUEST}
                className="h5 text-violet fw-600"
              >
                Organization Requests
              </Link>{" "}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminHomeComponent;
