import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

import { add } from "firebase_config";
import { post } from "services";
import { Tables, Routes } from "constants/index";
import { RichTextEditorComponent } from "components";

const AcceptComponent = ({
  request,
  options,
  setOptions,
  approveFlag,
  setApproveFlag,
}) => {
  const [loading, setLoading] = useState(false);
  const [richText, setRichText] = useState(false);
  const [doctorRemarks, setDoctorRemarks] = useState();
  const history = useHistory();

  const handleChange = (key, value, additionalField) => {
    if (additionalField) {
      setOptions({
        ...options,
        [key]: {
          ...options[key],
          [additionalField]: value,
        },
      });
    } else {
      setOptions({
        ...options,
        [key]: value,
      });
    }
  };

  const approveRequest = async () => {
    if (request.isPrescriptionRequired && !request.prescriptionLink) {
      toast.error(
        "Patient is requested for prescription. Create a prescription!"
      );
      return;
    }
    if (approveFlag && !options.reason && !options.defaultMessage) {
      toast.error(
        "Patient issue is mandatory. It needs to be printed on certificate!"
      );
      return;
    }
    setLoading(true);
    const res = await post("/request_accept", {
      status: "approved",
      requestId: request.id,
      doctorRemarks,
      ...options,
      richText,
    });
    if (res.status === 201) {
      toast.success("Request is approved.");
      add(Tables.LOGS, {
        text: "Your certificate is ready to download",
        receiverId: request.userId,
        actionLink: `/app/posts/${request.id}`,
        unread: true,
      });
      add(Tables.LOGS, {
        text: "Give feedback and win 20% discount on your next certificate!",
        receiverId: request.userId,
        actionLink: `/app/posts/${request.id}`,
        unread: true,
      });
      history.push(Routes.APP_REQUESTS);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="col-12">
        {(request.type === "fitness_certificate" ||
          request.type === "custom_certificate") && (
          <div className="mt-3">
            <div className="font-small fw-500 text-secondary">
              Information you want to add: <br />
              <small>This'll be printed on certificate</small>
            </div>
            <div>
              <textarea
                rows="4"
                className="form-control font-small mt-1"
                value={options.defaultMessage}
                onChange={(e) => handleChange("defaultMessage", e.target.value)}
              />
              <div className="fs-10 fw-500 mt-1 text-secondary">
                {options.defaultMessage?.length} chars. For custom certificates:
                maximum 400 chars allowed.
              </div>
            </div>
          </div>
        )}
        {(!request.type ||
          request.type === "caretaker_certificate" ||
          request.type === "recovery_certificate") && (
          <>
            <div className="mt-3">
              <div className="d-none">
                <RichTextEditorComponent setRichText={setRichText} />
              </div>
              <div className="font-small fw-500 text-secondary">
                Patient Issue <br />
                <small>This'll be printed on certificate</small>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control font-small mt-1"
                  value={options.reason}
                  onChange={(e) => handleChange("reason", e.target.value)}
                />
              </div>
            </div>
            <div className="mt-3">
              <div className="font-small fw-500 text-secondary">
                Leave date from
              </div>
              <div>
                <input
                  type="date"
                  className="form-control"
                  value={options.leaveDateFrom}
                  onChange={(e) =>
                    handleChange("leaveDateFrom", e.target.value)
                  }
                  max={moment().format("DD/MM/YYYY")}
                />
              </div>
            </div>
            <div className="mt-3">
              <div className="font-small fw-500 text-secondary">
                Leave date to
              </div>
              <div>
                <input
                  type="date"
                  className="form-control"
                  value={options.leaveDateTo}
                  onChange={(e) => handleChange("leaveDateTo", e.target.value)}
                  max={moment().format("DD/MM/YYYY")}
                />
              </div>
            </div>
          </>
        )}
        <div className="mt-3">
          <div className="font-small fw-500 text-secondary">
            Any personal message to patient:
          </div>
          <div>
            <textarea
              className="form-control font-small mt-1"
              placeholder="This message is not printed on certificate"
              onChange={(e) => setDoctorRemarks(e.target.value)}
            />
          </div>
        </div>
        <hr />
        <div className="">
          <h6>Extra Verification</h6>
          <div className="mt-3">
            <div className="font-small fw-500 text-secondary">
              Is customer identification verified? <br />
              <small>
                Name and adddress must match with the uploaded documents.
              </small>
            </div>
            <div className="col-12 py-1 ml-1 fs-12 pl-0">
              <select
                className="form-control font-small mt-1"
                aria-label=".form-select-sm"
                onChange={(e) =>
                  handleChange(
                    "extraDetails",
                    e.target.value,
                    "customerVerification"
                  )
                }
              >
                <option value="id_not_verified">No, I didn't verify ID.</option>
                <option value="id_verified_correct_details">
                  Yes, ID is verified and all details are correct.
                </option>
                <option value="id_verified_incorrect_details">
                  Yes, ID is verified and details are incorrect.
                </option>
              </select>
            </div>
          </div>
          <div className="mt-3">
            <div className="font-small fw-500 text-secondary">
              Is organization name and address correct? <br />
              <small className="d-none">
                It is your responsibility to correct the details if its not.
              </small>
            </div>
            <div className="col-12 py-1 ml-1 fs-12 pl-0">
              <select
                className="form-control font-small mt-1"
                aria-label=".form-select-sm"
                onChange={(e) =>
                  handleChange(
                    "extraDetails",
                    e.target.value,
                    "customerOrganizationVerification"
                  )
                }
              >
                <option value="organization_not_verified">
                  No, I didn't check. I don't care.
                </option>
                <option value="organization_verified_correct_details">
                  Yes, I asked and confirmed by patient. All details are
                  correct.
                </option>
              </select>
            </div>
          </div>
          <div className="mt-3">
            <div className="font-small fw-500 text-secondary">
              How'd the customer know about Medoc? <br />
            </div>
            <select
              className="form-control font-small"
              aria-label=".form-select-sm"
              onChange={(e) =>
                handleChange(
                  "extraDetails",
                  e.target.value,
                  "customerVisitedVia"
                )
              }
            >
              <option value="nothing">I didn't ask</option>
              <option value="social_media">Social media</option>
              <option value="online_search">Online search</option>
              <option value="friend_referral">Friend Referral</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="mt-3">
            <div className="font-small fw-500 text-secondary">
              Did you ask patient to provide feedback about their experience?{" "}
              <br />
            </div>
            <select
              className="form-control font-small mt-1"
              aria-label=".form-select-sm"
              onChange={(e) =>
                handleChange(
                  "extraDetails",
                  e.target.value,
                  "askedCustomerFeedback"
                )
              }
            >
              <option value="no">No, I didn't ask</option>
              <option value="yes">Yes, I asked</option>
            </select>
          </div>
        </div>
        <div className="mt-3 col-12 text-center">
          <button
            className="btn btn-violet btn-sm py-2 my-2  col-8 col-sm-6 fw-500"
            onClick={approveRequest}
            disabled={loading}
          >
            <i className="fa fa-paper-plane pr-2" />
            Send Certificate {loading && <div className="spinner-border" />}
          </button>{" "}
          <button
            className="btn btn-danger-outline btn-sm py-2 my-2  col-8 col-sm-6"
            onClick={(e) => setApproveFlag(false)}
            disabled={loading}
          >
            Go Back
          </button>{" "}
        </div>
      </div>
    </>
  );
};

export default AcceptComponent;
