import React, { useState } from "react";
import { toast } from "react-toastify";

import { getQuery, firestore } from "firebase_config";
import { Tables, ENV } from "constants/index";
import HeaderComponent from "./header";
import LineChart from "components/analysis/linechart/component";

function DoctorAnalyticsComponent({ currentUser }) {
  const [medRequests, setMedRequests] = useState();
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
  });
  const [newPayment, setNewPayment] = useState({
    totalAmount: 0,
    deductions: 0,
    netAmount: 0,
    payLength: 0,
    cpayLength: 0,
    startDate: "",
    endDate: "",
  });

  const handleChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const searchRecords = async () => {
    const startDate = new Date(filters?.startDate).getTime();
    const endDate = new Date(filters?.endDate).getTime() + 23 * 60 * 60 * 1000;
    if (!filters.startDate || !filters.endDate) {
      toast.error("Start Date & End Date are mandatory");
      return;
    } else if (filters.startDate && filters.endDate && startDate > endDate) {
      toast.error("Start Date must be less than End Date");
      return;
    }

    const requests = await getQuery(
      firestore
        .collection(Tables.MEDICAL_REQUESTS)
        .where("doctorId", "==", currentUser.id)
        .where("updatedAt", ">", startDate)
        .where("updatedAt", "<=", endDate)
        .get()
    );
    const pay = requests.filter(
      (req) =>
        !req.isPrescriptionRequired &&
        req.status !== "pending" &&
        !req.reassigned
    ).length;
    const cpay = requests.filter(
      (req) =>
        req.isPrescriptionRequired &&
        req.status !== "pending" &&
        !req.reassigned
    ).length;

    const tp = currentUser.pay * pay + currentUser.payWithPrescription * cpay;
    setNewPayment({
      totalAmount: tp,
      deductions: tp * 0.1,
      netAmount: tp - tp * 0.1,
      payLength: pay,
      cpayLength: cpay,
      startDate: filters.startDate,
      endDate: filters.endDate,
    });
    setMedRequests(requests.filter((req) => req.status !== "pending"));
  };

  const clearSearch = () => {
    setFilters({
      startDate: "",
      endDate: "",
    });
  };

  const linechartData = () => {
    return [
      {
        label: "Consultations",
        data: currentUser.payments?.map(
          (p) => p.normalConsultations + p.prescriptionConsultations
        ),
        borderColor: "#072448",
        fill: false,
        borderWidth: 2,
        tension: 0.2,
      },
      {
        label: "Total Amount",
        data: currentUser.payments?.map((p) => p.totalAmount),
        borderColor: "#54d2d2",
        fill: false,
        borderWidth: 2,
        tension: 0.2,
      },
      {
        label: "Deductions",
        data: currentUser.payments?.map((p) => p.deductions),
        borderColor: "#f8aa4b",
        fill: false,
        borderWidth: 2,
        tension: 0.2,
      },
      {
        label: "Net Amount",
        data: currentUser.payments?.map((p) => p.netAmount),
        borderColor: "#ff6150",
        fill: false,
        borderWidth: 2,
        tension: 0.2,
      },
    ];
  };

  return (
    <div className="dashboard-content">
      <HeaderComponent />
      <div className="container settings-wrapper desktop-align-center pt-5">
        <div className="section">
          <div className="card px-3 py-2">
            <div className="row fs-12">
              <div className="col-12 col-sm-6 my-2">
                <label>Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="DD/MM/YYYY"
                  max={filters.startDate}
                  value={filters.startDate}
                  onChange={(e) => handleChange("startDate", e.target.value)}
                />
              </div>
              <div className="col-12 col-sm-6 my-2">
                <label>End Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="DD/MM/YYYY"
                  min={filters.startDate}
                  value={filters.endDate}
                  onChange={(e) => handleChange("endDate", e.target.value)}
                />
              </div>
              <div className="ml-3">
                <div className="btn btn-violet btn-sm" onClick={searchRecords}>
                  Search
                </div>
                <div
                  className="btn btn-outline-danger btn-sm ml-2"
                  onClick={clearSearch}
                >
                  Clear
                </div>
              </div>
            </div>
          </div>
          <h6 className="text-center mt-3">
            {filters.startDate} - {filters.endDate}
          </h6>
          <ul className="section-list">
            <li className="d-flex flex-row align-items-center justify-content-between">
              <span className="option-name">Total Amount</span>
              <span className="option-name">
                {newPayment.totalAmount > 0
                  ? newPayment.totalAmount.toFixed(2)
                  : "0.00"}{" "}
                {ENV.CURRENCY}
              </span>
            </li>
            <li className="d-flex flex-row align-items-center justify-content-between">
              <span className="option-name">Deductions</span>
              <span className="option-name">
                {newPayment.deductions > 0
                  ? newPayment.deductions.toFixed(2)
                  : "0.00"}{" "}
                {ENV.CURRENCY}
              </span>
            </li>
            <li className="d-flex flex-row align-items-center justify-content-between">
              <span className="option-name">Net Amount (withdrawable)</span>
              <span className="option-name">
                {newPayment.netAmount > 0
                  ? newPayment.netAmount.toFixed(2)
                  : "0.00"}{" "}
                {ENV.CURRENCY}
              </span>
            </li>
            <li className="d-flex flex-row align-items-center justify-content-between">
              <span className="option-name">Normal Consultations</span>
              <span className="option-name">{newPayment.payLength}</span>
            </li>
            <li className="d-flex flex-row align-items-center justify-content-between">
              <span className="option-name">Prescription Consultations</span>
              <span className="option-name">{newPayment.cpayLength}</span>
            </li>
          </ul>
        </div>
        {currentUser.payments?.length > 5 && (
          <LineChart
            title="Monthly Stats"
            medRequests={medRequests}
            data={linechartData()}
          />
        )}
      </div>
    </div>
  );
}

export default DoctorAnalyticsComponent;
