import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import AuthRoute from "./index";

import {
  LoginComponent,
  LogoutComponent,
  SignupComponent,
  SignupSuccessComponent,
  AlertsComponent,
  DisplayComponent,
  PaymentsComponent,
  SearchComponent,
  SearchFollowersComponent,
  SearchFollowingComponent,
  ForgotPasswordComponent,
  NotFoundComponent,
  CreateChatComponent,
  CreateChatLayerComponent,
  SupportComponent,
  ChatsComponent,
  ShowChatComponent,
  CreateCertificateComponent,
  EditCertificateComponent,
  CreateFitnessCertificateComponent,
  EditFitnessCertificateComponent,
  CreateRecoveryCertificateComponent,
  CreateCaretakerCertificateComponent,
  EditCaretakerCertificateComponent,
  CreateForm1aComponent,
  EditForm1aComponent,
  CreateSecondOpinionComponent,
  CreateActivityComponent,
  ResharePostComponent,
  SettingsComponent,
  PermissionsComponent,
  ChangePasswordComponent,
  EditProfileComponent,
  EditAvailabilityComponent,
  ProfileComponent,
  ChallengesComponent,
  ShowPostsComponent,
  PaymentCardsComponent,
  AddPaymentCardComponent,
  WalletComponent,
  WalletSettingsComponent,
  AddPriceComponent,
  PurchaseOrdersComponent,
  ShowPostComponent,
  ChangePasscodeComponent,
  WalletRechargeComponent,
  WalletWithdrawComponent,
  InviteFriendsComponent,
  ShowTransactionComponent,
  SendCompanyAlertsComponent,
  AnalysisComponent,
  WithdrawRequestsComponent,
  DoctorComponent,
  AssignRequestToDoctorComponent,
  MyRequestsComponent,
  SlcComponent,
  LeaveFeedbackComponent,
  ConsultDoctorComponent,
  ConsultDoctorAbroadComponent,
  CustomCertificateComponent,
  AllCertificatesComponent,
  DoctorPrescriptionComponent,
  RechargeSuccessComponent,
  AdminHomeComponent,
  GetMedicalRequestsComponent,
  GetClientRequestsComponent,
  GetOrganizationRequestsComponent,
  GetDoctorRequestsComponent,
  UpdateMedicalRequestComponent,
  UpdateUsersComponent,
  DoctorAnalyticsComponent,
  PublicDoctorAppointmentsComponent,
  PublicDoctorShowAppointmentComponent,
  PublicDoctorCreatePrescriptionComponent,
  PublicDoctorAnalyticsComponent,
} from "components";

const LandingComponent = () => {
  const token = window.sessionStorage.getItem("token");
  if (!token) {
    return <Redirect to="/login" />;
  }
  return <Redirect to="/app/posts" />;
};

export const Routes = (props) => {
  const location = useLocation();

  return (
    <main style={{ position: "relative", overflow: "hidden" }}>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/" component={LandingComponent} />
          <Route path="/login" component={LoginComponent} />
          <Route path="/logout" component={LogoutComponent} />
          <Route exact path="/signup" component={SignupComponent} />
          <Route exact path="/signup/:username" component={SignupComponent} />
          <Route
            exact
            path="/signup_success"
            component={SignupSuccessComponent}
          />
          <Route
            exact
            path="/forgot_password"
            component={ForgotPasswordComponent}
          />
          <AuthRoute exact path="/app" component={LandingComponent} />
          <AuthRoute exact path="/app/alerts" component={AlertsComponent} />
          <AuthRoute
            exact
            path="/app/create_post"
            component={CreateCertificateComponent}
          />
          <AuthRoute
            exact
            path="/app/edit_post"
            component={EditCertificateComponent}
          />
          <AuthRoute
            exact
            path="/app/create_fc"
            component={CreateFitnessCertificateComponent}
          />
          <AuthRoute
            exact
            path="/app/edit_fc"
            component={EditFitnessCertificateComponent}
          />
          <AuthRoute
            exact
            path="/app/create_rc"
            component={CreateRecoveryCertificateComponent}
          />
          <AuthRoute
            exact
            path="/app/create_ct"
            component={CreateCaretakerCertificateComponent}
          />
          <AuthRoute
            exact
            path="/app/edit_ct"
            component={EditCaretakerCertificateComponent}
          />
          <AuthRoute
            exact
            path="/app/create_fa"
            component={CreateForm1aComponent}
          />
          <AuthRoute
            exact
            path="/app/edit_fa"
            component={EditForm1aComponent}
          />
          <AuthRoute
            exact
            path="/app/create_cc"
            component={CustomCertificateComponent}
          />
          <AuthRoute
            exact
            path="/app/create_second_opinion"
            component={CreateSecondOpinionComponent}
          />
          <AuthRoute
            exact
            path="/app/reshare_asset"
            component={ResharePostComponent}
          />
          <AuthRoute
            exact
            path="/app/create_activity"
            component={CreateActivityComponent}
          />
          <AuthRoute exact path="/app/posts" component={ShowPostsComponent} />
          <AuthRoute
            exact
            path="/app/posts/:post_id"
            component={ShowPostComponent}
          />
          <AuthRoute exact path="/app/profile" component={ProfileComponent} />
          <AuthRoute
            exact
            path="/app/profile/:username"
            component={ProfileComponent}
          />
          <AuthRoute
            exact
            path="/app/profile/:user_id/add_price"
            component={AddPriceComponent}
          />
          <AuthRoute exact path="/app/wallet" component={WalletComponent} />
          <AuthRoute
            exact
            path="/app/wallet_settings"
            component={WalletSettingsComponent}
          />
          {/* <AuthRoute exact path="/app/profile/:name" component={PublicProfileComponent} /> */}
          <AuthRoute exact path="/app/settings" component={SettingsComponent} />
          <AuthRoute
            exact
            path="/app/settings/edit-profile"
            component={EditProfileComponent}
          />
          <AuthRoute
            exact
            path="/app/settings/edit-virtual-clinic"
            component={EditAvailabilityComponent}
          />
          <AuthRoute
            exact
            path="/app/settings/permissions"
            component={PermissionsComponent}
          />
          <AuthRoute
            exact
            path="/app/settings/change_password"
            component={ChangePasswordComponent}
          />
          <AuthRoute
            exact
            path="/app/settings/purchase_orders"
            component={PurchaseOrdersComponent}
          />
          <AuthRoute
            exact
            path="/app/settings/payment_cards"
            component={PaymentCardsComponent}
          />
          <AuthRoute
            exact
            path="/app/settings/add_payment_card"
            component={AddPaymentCardComponent}
          />
          <AuthRoute
            exact
            path="/app/settings/analytics"
            component={DoctorAnalyticsComponent}
          />
          <AuthRoute
            exact
            path="/app/settings/vc-analytics"
            component={PublicDoctorAnalyticsComponent}
          />
          <AuthRoute exact path="/app/payments" component={PaymentsComponent} />
          <AuthRoute exact path="/app/search" component={SearchComponent} />
          <AuthRoute
            exact
            path="/app/following"
            component={SearchFollowingComponent}
          />
          <AuthRoute
            exact
            path="/app/followers"
            component={SearchFollowersComponent}
          />
          <AuthRoute
            exact
            path="/app/question/:id"
            component={DisplayComponent}
          />
          <AuthRoute exact path="/app/chats" component={ChatsComponent} />
          <AuthRoute
            exact
            path="/app/chats/:id"
            component={ShowChatComponent}
          />
          <AuthRoute
            exact
            path="/app/chats/new/:id"
            component={CreateChatLayerComponent}
          />
          <AuthRoute
            exact
            path="/app/new_chat"
            component={CreateChatComponent}
          />
          <AuthRoute exact path="/app/support" component={SupportComponent} />
          <AuthRoute
            exact
            path="/app/challenges"
            component={ChallengesComponent}
          />
          <AuthRoute
            exact
            path="/app/change_passcode"
            component={ChangePasscodeComponent}
          />
          <AuthRoute
            exact
            path="/app/recharge"
            component={WalletRechargeComponent}
          />
          <AuthRoute
            exact
            path="/app/withdraw"
            component={WalletWithdrawComponent}
          />
          <AuthRoute
            exact
            path="/app/transactions/:id"
            component={ShowTransactionComponent}
          />
          <AuthRoute
            exact
            path="/app/invite_friends"
            component={InviteFriendsComponent}
          />
          <AuthRoute
            exact
            path="/app/send_company_alerts"
            component={SendCompanyAlertsComponent}
          />
          <AuthRoute
            exact
            path="/app/withdraw_requests"
            component={WithdrawRequestsComponent}
          />
          <AuthRoute exact path="/app/analysis" component={AnalysisComponent} />
          <AuthRoute exact path="/app/doctor" component={DoctorComponent} />
          <AuthRoute
            exact
            path="/app/assign_to_doctor"
            component={AssignRequestToDoctorComponent}
          />
          <AuthRoute
            exact
            path="/app/requests"
            component={MyRequestsComponent}
          />
          <AuthRoute
            exact
            path="/app/guidelines_slc"
            component={SlcComponent}
          />
          <AuthRoute
            exact
            path="/app/leave_feedback"
            component={LeaveFeedbackComponent}
          />
          <AuthRoute
            exact
            path="/app/consult_doctor"
            component={ConsultDoctorComponent}
          />
          <AuthRoute
            exact
            path="/app/consult_doctor_abroad"
            component={ConsultDoctorAbroadComponent}
          />
          <AuthRoute
            exact
            path="/app/choose_certificate_type"
            component={AllCertificatesComponent}
          />
          <AuthRoute
            exact
            path="/app/create_doctor_prescription"
            component={DoctorPrescriptionComponent}
          />
          <AuthRoute
            exact
            path="/app/recharge_success"
            component={RechargeSuccessComponent}
          />
          <AuthRoute
            exact
            path="/app/admin_home"
            component={AdminHomeComponent}
          />
          <AuthRoute
            exact
            path="/app/get_medical_request"
            component={GetMedicalRequestsComponent}
          />
          <AuthRoute
            exact
            path="/app/get_client_request"
            component={GetClientRequestsComponent}
          />
          <AuthRoute
            exact
            path="/app/get_organization_request"
            component={GetOrganizationRequestsComponent}
          />
          <AuthRoute
            exact
            path="/app/get_doctor_request"
            component={GetDoctorRequestsComponent}
          />
          <AuthRoute
            exact
            path="/app/update_medical_request"
            component={UpdateMedicalRequestComponent}
          />
          <AuthRoute
            exact
            path="/app/update_user"
            component={UpdateUsersComponent}
          />
          <AuthRoute
            exact
            path="/app/appointments"
            component={PublicDoctorAppointmentsComponent}
          />
          <AuthRoute
            exact
            path="/app/appointments/:id"
            component={PublicDoctorShowAppointmentComponent}
          />
          <AuthRoute
            exact
            path="/app/appointments/:id/prescription"
            component={PublicDoctorCreatePrescriptionComponent}
          />
          <Route path="*" component={NotFoundComponent} />
          <Redirect to="/" />
        </Switch>
      </AnimatePresence>
    </main>
  );
};

export default Routes;
