import React, { Component } from "react";
import { Link } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";
import { toast } from "react-toastify";
import "./style.css";

import SpecialHeaderComponent from "./children/special_header/component";
import {
  ClinicsComponent,
  HeaderComponent,
  LoadingComponent,
} from "components";
import { getQuery, firestore } from "firebase_config";
import { Tables, Routes } from "constants/index";

class ShowPostsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      isLevelOneDoctor: props.currentUser.roles.find(
        (role) => role === "levelOne"
      ),
      isLevelTwoDoctor: props.currentUser.roles.find(
        (role) => role === "levelTwo"
      ),
      isAdmin: props.currentUser.roles.find((role) => role === "admin"),
      showPaymentPendingMessage: false,
      paymentPendingPost: {},
      virtualClinic: "",
      virtualClinicUrl: `https://medoc.life/dr/${props.currentUser.username}`,
    };
  }

  componentDidMount() {
    if (this.state.isAdmin) {
      this.props.history.push(Routes.APP_ADMIN_HOME);
      return;
    }
    if (this.state.isLevelOneDoctor) {
      this.loadPublicProfile();
    }
  }

  loadPublicProfile = async () => {
    const vc = await getQuery(
      firestore
        .collection(Tables.DC_PUBLIC_PROFILE)
        .where("userId", "==", this.props.currentUser.id)
        .get()
    );
    if (vc[0]) {
      this.setState({
        virtualClinic: vc[0],
      });
    }
  };

  copyToClipboard = (text, successMsg) => {
    navigator.clipboard.writeText(text);
    toast.success(successMsg);
  };

  render() {
    return (
      <>
        <HeaderComponent />
        <div className="pt-3 mt-5">
          {this.state.showPaymentPendingMessage && !this.state.isAdmin && (
            <SpecialHeaderComponent
              currentUser={this.currentUser}
              paymentPendingPost={this.state.paymentPendingPost}
            />
          )}
          <div className="container pt-4">
            {this.state.isLevelTwoDoctor && (
              <>
                <h5 className="text-center py-3">Medoc Doctor (Level 2)</h5>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-4 product-card">
                    <div className="card mx-2 mb-4 mx-sm-0">
                      <div className="h5 text-violet fw-600">
                        Assign Request
                      </div>
                      <p className="card-text mt-2 fs-12">
                        Check certificate requests and make a decision. Be
                        precise and clear while talking to patients.
                      </p>
                      <Link
                        to={Routes.APP_DOCTOR}
                        className="btn btn-violet btn-sm py-2"
                      >
                        Assign
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 product-card">
                    <div className="card mx-2 mb-4 mx-sm-0">
                      <div className="h5 text-violet fw-600 d-flex flex-row align-items-center">
                        My Certificates
                      </div>
                      <p className="card-text mt-2 fs-12">
                        Manage all your certificate requests. Create
                        prescriptions, search requests & more.
                      </p>
                      <Link
                        to={Routes.APP_REQUESTS}
                        className="btn btn-violet btn-sm py-2"
                      >
                        Show All
                      </Link>{" "}
                    </div>
                  </div>
                </div>
                <hr className="col-12" />
              </>
            )}
            {this.state.isLevelOneDoctor && (
              <>
                <h5 className="text-center py-3">
                  Virtual Clinic Doctor (Level 1)
                </h5>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-4 product-card">
                    <div className="card mx-2 mb-4 mx-sm-0">
                      <div className="text-center">
                        <QRCodeCanvas
                          value={this.state.virtualClinicUrl}
                          size={77}
                        />
                      </div>
                      <div
                        onClick={(e) =>
                          this.copyToClipboard(
                            this.state.virtualClinicUrl,
                            "Virtual Clinic Url Copied!"
                          )
                        }
                        className="btn btn-violet btn-sm py-2 mt-2"
                      >
                        Copy Virtual Clinic Url
                      </div>{" "}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 product-card">
                    <div className="card mx-2 mb-4 mx-sm-0">
                      <div className="h5 text-violet fw-600">
                        My Appointments
                      </div>
                      <p className="card-text mt-2 fs-12">
                        Single place to manage all your appointments. Create
                        prescriptions, send feedbacks & more.
                      </p>
                      <Link
                        to={Routes.APP_DC_PUBLIC_APPOINTMENTS}
                        className="btn btn-violet btn-sm py-2"
                      >
                        See all
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 product-card">
                    <div className="card mx-2 mb-4 mx-sm-0">
                      <div className="h5 text-violet fw-600 d-flex flex-row align-items-center">
                        Virtual Clinic
                        {!this.state.virtualClinic ? (
                          <span className="text-danger pl-2 fs-12 fw-500">
                            <i className="fa fa-dot-circle-o" /> Pending
                          </span>
                        ) : (
                          <a
                            href={`https://medoc.life/dr/${this.state.virtualClinic.username}`}
                            className="text-success pl-2 fs-12 fw-500"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <i className="fa fa-dot-circle-o" /> Live
                          </a>
                        )}
                      </div>
                      <p className="card-text mt-2 fs-12">
                        You are in charge of your clinic. It is important to
                        ensure that both your clinic and patients are well taken
                        care of.
                      </p>
                      <Link
                        to={Routes.APP_EDIT_VIRTUAL_CLINIC}
                        className="btn btn-violet btn-sm py-2"
                      >
                        {this.state.virtualClinic ? "Manage" : "Create"}
                      </Link>{" "}
                    </div>
                  </div>
                </div>
                <hr className="col-12" />
              </>
            )}
            {this.state.loading && <LoadingComponent />}
          </div>
          <ClinicsComponent />
        </div>
      </>
    );
  }
}

export default ShowPostsComponent;
