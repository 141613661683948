import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

import HeaderComponent from "./header";
import { CertificateTypes } from "constants/index";

const AllCertificatesComponent = ({ currentUser }) => {
  return (
    <>
      <HeaderComponent headerText="Select Certificate Type" />
      <div className="dashboard-content choose-certificate-wrapper">
        <div className="container pt-5 mt-sm-5">
          <div className="mt-sm-5"></div>
          <div className="card py-4 px-2 p-sm-3">
            {CertificateTypes.map(
              (t, i) =>
                t.display && (
                  <Link
                    to={{
                      pathname: t.route,
                      state: t.state,
                    }}
                    key={i}
                    className="col-12 py-2"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="reason"
                      id={`reason-${i}`}
                    />
                    <label
                      className="form-check-label text-violet fw-500"
                      htmlFor={`reason-${i}`}
                    >
                      {t.name}
                    </label>
                  </Link>
                )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllCertificatesComponent;
