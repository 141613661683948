import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./style.css";

import { Routes } from "constants/index";

const HeaderComponent = ({
  newMessagesCount,
  newAlertsCount,
  pendingRelationsCount,
  currentUser,
}) => {
  const isDoctor = currentUser?.isDoctor;
  const isAdmin = currentUser?.isAdmin;
  const isMobile = window.innerWidth < 576;
  return (
    <>
      <nav className="navbar fixed-top navbar-violet">
        <div className="container p-0">
          <div className="mr-auto">
            <Link className="navbar-brand" to={Routes.APP_POSTS}>
              medoc <small>doctor</small>
            </Link>
          </div>
          <ul className="navbar-nav ml-auto">
            <li className={`nav-item ${window.innerWidth < 576 && "d-none"}`}>
              <div className="nav-link px-2 px-sm-3">
                <Link to={Routes.APP_POSTS} title="Home">
                  <img
                    src={require("assets/svgs/HomeWhite.svg").default}
                    className="icon-home"
                    alt="Home"
                  />
                  <div
                    className={`fs-12 fw-500 text-white pt-1 ${
                      isMobile && "d-none"
                    }`}
                  >
                    Home
                  </div>
                </Link>
              </div>
            </li>
            <li className={`${isAdmin && "d-none"} nav-item`}>
              <div className="nav-link px-2 px-sm-3">
                <Link to={Routes.APP_ALERTS} title="Alerts">
                  <img
                    src={require("assets/svgs/Bell.svg").default}
                    className="icon-alert"
                    alt="Alerts"
                  />
                  {(pendingRelationsCount > 0 || newAlertsCount > 0) && (
                    <sup>
                      <img
                        src={require("assets/svgs/DotActive.svg").default}
                        className="dot-alert-icon"
                        alt="Dot"
                      />
                    </sup>
                  )}
                  <div
                    className={`fs-12 fw-500 text-white pt-1 ${
                      isMobile && "d-none"
                    }`}
                  >
                    Notifications
                  </div>
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-link px-2 px-sm-3">
                <Link to={Routes.APP_SETTINGS} title="Profile">
                  <img
                    src={require("assets/svgs/ProfileWhite.svg").default}
                    className="icon-profile"
                    alt="Profile"
                  />
                  <div
                    className={`fs-12 fw-500 text-white pt-1 ${
                      isMobile && "d-none"
                    }`}
                  >
                    Profile
                  </div>
                </Link>
              </div>
            </li>
            <li className="nav-item d-none">
              <div className="nav-link p-0">
                <Link
                  to="/app/create_post"
                  className={`d-none ${
                    (window.innerWidth < 576 ||
                      isDoctor ||
                      !currentUser?.admin) &&
                    "d-none"
                  }`}
                  title="Create"
                >
                  <img
                    src={require("assets/svgs/Plus.svg").default}
                    className="icon-post"
                    alt="Chats"
                  />
                </Link>
                <Link
                  to="/app/posts"
                  className={`${window.innerWidth < 576 && "d-none"}`}
                  title="Home"
                >
                  <img
                    src={require("assets/svgs/HomeWhite.svg").default}
                    className="icon-home"
                    alt="Home"
                  />
                </Link>
                <Link to="/app/alerts" title="Alerts">
                  <img
                    src={require("assets/svgs/Bell.svg").default}
                    className="icon-alert"
                    alt="Alerts"
                  />
                  {(pendingRelationsCount > 0 || newAlertsCount > 0) && (
                    <sup>
                      <img
                        src={require("assets/svgs/DotActive.svg").default}
                        className="dot-alert-icon"
                        alt="Dot"
                      />
                    </sup>
                  )}
                </Link>
                <Link to="/app/settings" title="Profile">
                  <img
                    src={require("assets/svgs/ProfileWhite.svg").default}
                    className="icon-profile"
                    alt="Profile"
                  />
                </Link>
              </div>
              <div className="d-none">
                <Link to="/app/search" title="Search">
                  <img
                    src={require("assets/svgs/Search.svg").default}
                    className="icon-search"
                    alt="Search"
                  />
                </Link>
                <Link to="/app/chats" title="Chat">
                  <img
                    src={require("assets/svgs/Chat.svg").default}
                    className="icon-chat"
                    alt="Chats"
                  />
                  {newMessagesCount > 0 && (
                    <sup>
                      <img
                        src={require("assets/svgs/DotActive.svg").default}
                        className="dot-chat-icon"
                        alt="Dot"
                      />
                    </sup>
                  )}
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

const mapStateToProps = (state) => {
  const { newAlertsCount } = state.alerts;
  const { newMessagesCount } = state.chatMessages;
  const { pendingRelationsCount } = state.relationships;
  return { newAlertsCount, newMessagesCount, pendingRelationsCount };
};

export default connect(mapStateToProps, null)(HeaderComponent);
