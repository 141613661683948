import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import "./style.css";

import HeaderComponent from "./header";
import FiltersComponent from "./filter/component";
import { Routes, Tables } from "constants/index";
import { add, update } from "firebase_config";
import { post } from "services";
import { capitalizeFirstLetter } from "helpers";

const MyRequestsComponent = ({ currentUser }) => {
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState();
  const [feedback, setFeedback] = useState({
    feedback: false,
    feedbackType: "",
    feedbackComment: "",
  });
  const isDoctor = currentUser.isDoctor;
  const history = useHistory();
  const storageUrl =
    "https://storage.googleapis.com/medoc-live.appspot.com/images/";

  const redirectToCheckout = (request) => {
    history.push({
      pathname: Routes.APP_RECHARGE,
      state: {
        requestId: request.id,
        amount: request.product.price,
      },
    });
  };

  const saveFeedback = async (request) => {
    add(Tables.MEDICAL_REQUEST_FEEDBACKS, {
      type: feedback.feedbackType,
      comment: feedback.feedbackComment,
      requestId: request.id,
      userId: currentUser.id,
    });
    await update(Tables.MEDICAL_REQUESTS, request.id, {
      feedback: true,
    });
    toast.success("Thank you for your feedback!");
    setFeedback({
      feedback: true,
      feedbackType: "",
      feedbackComment: "",
    });
  };

  const redirectToRequest = (req) => {
    history.push(`${Routes.APP_POSTS}/${req.id}`);
  };

  const redirectToEditPost = (req) => {
    let pathname;
    if (req.type === "caretaker_certificate") {
      pathname = Routes.APP_EDIT_CT;
    } else if (req.type === "fitness_certificate") {
      pathname = Routes.APP_EDIT_FC;
    } else if (req.type === "form_1a") {
      pathname = Routes.APP_EDIT_FA;
    } else {
      pathname = Routes.APP_EDIT_POST;
    }
    history.push({
      pathname,
      state: {
        request: req,
      },
    });
  };

  const rejectRequest = async (req) => {
    const refundAmount = req.product.price >= 1000 ? 500 : 250;
    if (
      window.confirm(
        `Are you sure to cancel request and refund? Note: There's a service charge of ${refundAmount} INR/-`
      )
    ) {
      setLoading(true);
      // send a BE request
      const res = await post("/request_reject", {
        status: "rejected",
        requestId: req.id,
        rejectReason: "real",
      });

      if (res.status === 201) {
        toast.success("Request is cancelled.");
        history.push(`${Routes.APP_POSTS}/${req.id}`);
      } else {
        toast.error(res.data.message);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderComponent />
      {loading ? (
        <div className="dashboard-content text-center mt-5 pt-5">
          <div className="spinner-border"></div>
        </div>
      ) : (
        <div className="dashboard-content requests-wrapper mt-3 mt-sm-5 pt-5">
          <FiltersComponent
            currentUser={currentUser}
            requests={requests}
            setRequests={setRequests}
          />
          <div className="fs-14 px-2 px-sm-0 my-2">
            Total Consultations: {requests?.length}
          </div>
          {requests && requests.length > 0 ? (
            requests.map((request, idx) => (
              <div className="card request-card p-3 mb-3 ml-2 mr-2" key={idx}>
                <div
                  className={`col-5 col-sm-3 col-lg-1 badge ${
                    request.status === "pending" && "badge-secondary"
                  } ${request.status === "accepted" && "badge-success"} ${
                    request.status === "rejected" && "badge-danger"
                  } py-1 fw-500`}
                  onClick={(e) => redirectToRequest(request)}
                >
                  {capitalizeFirstLetter(request.status)}
                </div>
                <div
                  className="row pointer"
                  onClick={(e) => redirectToRequest(request)}
                >
                  <div className="mt-3 col-12 col-sm-6">
                    <div className="font-small fw-500 text-secondary">
                      Request type
                    </div>
                    <div>
                      {request.type
                        ? capitalizeFirstLetter(request.type.replace(/_/g, " "))
                        : "Sick leave certificate"}
                    </div>
                  </div>
                  <div className="mt-3 col-12 col-sm-6">
                    <div className="font-small fw-500 text-secondary">
                      Request Number
                    </div>
                    <div>{request.id}</div>
                  </div>
                  <div className="mt-3 col-12 col-sm-6">
                    <div className="font-small fw-500 text-secondary">Name</div>
                    <div>
                      {request.firstName} {request.lastName}
                    </div>
                  </div>
                  <div className="mt-3 col-12 col-sm-6">
                    <div className="font-small fw-500 text-secondary">
                      Last updated
                    </div>
                    <div>
                      {moment(request.updatedAt).format("DD-MM-YYYY, HH:mm")}
                    </div>
                  </div>
                  {request.leaveReason === "Sick Leave" && (
                    <>
                      <div className="mt-3 col-12 col-sm-6">
                        <div className="font-small fw-500 text-secondary">
                          Leave Date From
                        </div>
                        <div>
                          {moment(request.leaveDateFrom).format("DD-MM-YYYY")}
                        </div>
                      </div>
                      <div className="mt-3 col-12 col-sm-6">
                        <div className="font-small fw-500 text-secondary">
                          Leave Date To
                        </div>
                        <div>
                          {moment(request.leaveDateTo).format("DD-MM-YYYY")}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {request.certLink && (
                  <div className="mt-3 col-12 col-sm-6 pl-0">
                    <div className="font-small fw-500 text-secondary">
                      Certificate Link
                    </div>
                    <a
                      href={request.certLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-violet fw-500"
                    >
                      Download here <i className="fa fa-arrow-circle-o-down" />
                    </a>
                  </div>
                )}
                {request.prescriptionLink && (
                  <div className="mt-3 col-12 col-sm-6 pl-0">
                    <div className="font-small fw-500 text-secondary">
                      Prescription Link
                    </div>
                    <a
                      href={request.prescriptionLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-violet fw-500"
                    >
                      Download here <i className="fa fa-arrow-circle-o-down" />
                    </a>
                  </div>
                )}
                {!isDoctor && request.paymentStatus === "pending" && (
                  <div className="mt-2 text-danger fs-sm-12">
                    <hr />
                    <span
                      className="fw-500 pointer"
                      onClick={(e) => redirectToCheckout(request)}
                    >
                      <i className="fa fa-warning pr-1" />
                      Complete payment to{" "}
                      {request.type === "consult_doctor"
                        ? "consult doctor buddy"
                        : "download your certificate"}
                    </span>
                  </div>
                )}
                {!isDoctor &&
                  request.type !== "consult_doctor" &&
                  request.additionalInfoRequired && (
                    <div className="mt-2 fs-sm-12 text-violet">
                      <hr />
                      <span
                        className="fw-500 pointer"
                        onClick={(e) => redirectToRequest(request)}
                      >
                        <i className="fa fa-info-circle pr-1" />
                        Your doctor buddy is waiting for more information.
                        <span className="ml-1">Respond here</span>
                      </span>
                    </div>
                )}
                {!isDoctor &&
                  request.paymentStatus === "completed" &&
                  request.isEditRequired &&
                  !request.additionalInfoRequired &&
                  request.status === "pending" && (
                    <div className="mt-2 fs-sm-12 text-danger">
                      <hr />
                      <span
                        className="fw-500 pointer"
                        onClick={(e) => redirectToEditPost(request)}
                      >
                        <i className="fa fa-warning pr-1" />
                        <u>
                          You must complete this form to submit your request.
                        </u>{" "}
                        &nbsp;
                        <div className="btn btn-danger btn-sm fs-10">
                          Click here
                        </div>
                      </span>
                    </div>
                )}
                {!isDoctor &&
                  request.paymentStatus === "completed" &&
                  !request.hideCancelButton &&
                  !request.additionalInfoRequired &&
                  !request.assigned &&
                  request.status === "pending" && (
                    <div className="mt-2 fs-sm-12 text-danger">
                      <hr />
                      <span
                        className="fw-500 pointer"
                        onClick={(e) => rejectRequest(request)}
                      >
                        <i className="fa fa-warning pr-1" />
                        <u>Cancel Request & Refund</u>
                      </span>
                    </div>
                )}
                {!isDoctor &&
                  request.type !== "consult_doctor" &&
                  request.paymentStatus === "completed" &&
                  !request.isEditRequired &&
                  !request.additionalInfoRequired &&
                  request.assigned &&
                  request.status === "pending" && (
                    <div className="mt-2 fs-sm-12 text-violet">
                      <hr />
                      <span
                        className="fw-500 pointer"
                        onClick={(e) => redirectToRequest(request)}
                      >
                        <i className="fa fa-info-circle pr-1" />
                        Review is in progress. You will be notified via email
                        once the review is done.
                      </span>
                    </div>
                )}
                {!isDoctor &&
                  request.status === "accepted" &&
                  !request.feedback && (
                    <div className="mt-2 pr-2">
                      <hr />
                      <a
                        href="https://www.trustpilot.com/evaluate/medoc.life"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="fs-sm-12 fw-500 text-violet">
                          Give feedback and win 10% discount on your next
                          certificate!
                        </span>
                        <span className="px-2">
                          <img
                            src={`${storageUrl}${
                              feedback.feedbackType === "like"
                                ? "like.png"
                                : "like_outline.png"
                            }`}
                            alt="like"
                            className="feedback-img"
                            onClick={(e) =>
                              setFeedback({
                                feedbackComment: "",
                                feedbackType: "",
                              })
                            }
                          />
                        </span>
                        <span>
                          <img
                            src={`${storageUrl}${
                              feedback.feedbackType === "dislike"
                                ? "dislike.png"
                                : "dislike_outline_1.png"
                            }`}
                            alt="dislike"
                            className="feedback-img"
                            onClick={(e) =>
                              setFeedback({
                                feedbackComment: "",
                                feedbackType: "",
                              })
                            }
                          />
                        </span>
                      </a>
                      {feedback.feedbackType && (
                        <div className="fs-10">
                          <textarea
                            className="form-control font-small col-10 col-5 mt-2"
                            placeholder="Write a comment!"
                            onChange={(e) =>
                              setFeedback({
                                feedbackComment: e.target.value,
                                feedbackType: feedback.feedbackType,
                              })
                            }
                          />
                          <div className="mt-2">
                            <button
                              className="btn btn-violet btn-sm fs-10 mr-2"
                              onClick={(e) => saveFeedback(request)}
                            >
                              Submit
                            </button>
                            <span className="d-none">
                              * Use promocode <b>MEDOC_20D</b> to avail 20%
                              discount on your next purchase!
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                )}
                <div className="text-center pl-sm-2">
                  <div
                    className="btn btn-violet btn-sm pointer col-6 col-sm-3 py-1 d-none"
                    onClick={(e) =>
                      history.push(`${Routes.APP_POSTS}/${request.id}`)
                    }
                  >
                    View details <i className="fa fa-arrow-circle-o-right"></i>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="pt-5 text-center">
              <div> No certificates yet </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MyRequestsComponent;
