import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

import { capitalizeFirstLetter } from "helpers";
import { getQuery, firestore } from "firebase_config";
import { Routes, Tables, ENV } from "constants/index";
import HeaderComponent from "./header";

function DoctorAnalyticsComponent({ currentUser }) {
  const [medRequests, setMedRequests] = useState();
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
  });
  const [newPayment, setNewPayment] = useState({
    totalAmount: 0,
    deductions: 0,
    netAmount: 0,
    payLength: 0,
    cpayLength: 0,
    startDate: "",
    endDate: "",
  });

  const handleChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const searchRecords = async () => {
    const startDate = new Date(filters?.startDate).getTime();
    const endDate = new Date(filters?.endDate).getTime() + 23 * 60 * 60 * 1000;
    if (!filters.startDate || !filters.endDate) {
      toast.error("Start Date & End Date are mandatory");
      return;
    } else if (filters.startDate && filters.endDate && startDate > endDate) {
      toast.error("Start Date must be less than End Date");
      return;
    }

    let requests = await getQuery(
      firestore
        .collection(Tables.DC_PUBLIC_APPOINTMENTS)
        .where("doctorId", "==", currentUser.id)
        .where("resolvedAt", ">", startDate)
        .where("resolvedAt", "<=", endDate)
        .get()
    );

    requests = requests.filter((req) => req.status !== "pending");
    const tp = requests.reduce((acc, obj) => {
      return acc + +obj.product?.cost;
    }, 0);

    setNewPayment({
      totalAmount: tp,
      deductions: tp * 0.1,
      netAmount: tp - tp * 0.1,
      totalAppointments: requests.length || 0,
      startDate: filters.startDate,
      endDate: filters.endDate,
    });
    setMedRequests(requests.filter((req) => req.status !== "pending"));
  };

  const clearSearch = () => {
    setFilters({
      startDate: "",
      endDate: "",
    });
  };

  return (
    <div className="dashboard-content">
      <HeaderComponent />
      <div className="container settings-wrapper desktop-align-center pt-5">
        <div className="section">
          <div className="card px-3 py-2">
            <div className="row fs-12">
              <div className="col-12 col-sm-6 my-2">
                <label>Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="DD/MM/YYYY"
                  max={filters.startDate}
                  value={filters.startDate}
                  onChange={(e) => handleChange("startDate", e.target.value)}
                />
              </div>
              <div className="col-12 col-sm-6 my-2">
                <label>End Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="DD/MM/YYYY"
                  min={filters.startDate}
                  value={filters.endDate}
                  onChange={(e) => handleChange("endDate", e.target.value)}
                />
              </div>
              <div className="ml-3">
                <div className="btn btn-violet btn-sm" onClick={searchRecords}>
                  Search
                </div>
                <div
                  className="btn btn-outline-danger btn-sm ml-2"
                  onClick={clearSearch}
                >
                  Clear
                </div>
              </div>
            </div>
          </div>
          <h6 className="text-center mt-3">
            {filters.startDate} - {filters.endDate}
          </h6>
          <ul className="section-list">
            <li className="d-flex flex-row align-items-center justify-content-between">
              <span className="option-name">Total Amount</span>
              <span className="option-name">
                {newPayment.totalAmount > 0
                  ? newPayment.totalAmount.toFixed(2)
                  : "0.00"}{" "}
                {ENV.CURRENCY}
              </span>
            </li>
            <li className="d-flex flex-row align-items-center justify-content-between">
              <span className="option-name">Deductions</span>
              <span className="option-name">
                {newPayment.deductions > 0
                  ? newPayment.deductions.toFixed(2)
                  : "0.00"}{" "}
                {ENV.CURRENCY}
              </span>
            </li>
            <li className="d-flex flex-row align-items-center justify-content-between">
              <span className="option-name">Net Amount (withdrawable)</span>
              <span className="option-name">
                {newPayment.netAmount > 0
                  ? newPayment.netAmount.toFixed(2)
                  : "0.00"}{" "}
                {ENV.CURRENCY}
              </span>
            </li>
            <li className="d-flex flex-row align-items-center justify-content-between">
              <span className="option-name">Appointments</span>
              <span className="option-name">{newPayment.payLength}</span>
            </li>
          </ul>
        </div>
        <div className="d-none">
          <h6>Payment dates</h6>
          {currentUser.payments &&
            currentUser.payments.map((payment, i) => (
              <div key={i} className="card">
                Payment Date:{" "}
                <b>
                  {payment.paymentDate} - {payment.paymentMonth}{" "}
                  {payment.paymentYear}
                </b>{" "}
                <br />
                Total Amount: {payment.totalAmount} <br />
                Deductions: {payment.deductions} <br />
                Net Amount: {payment.netAmount}
              </div>
            ))}
        </div>
        <table className="table table-bordered table-responsive d-none">
          <thead>
            <tr>
              <th>Request Number</th>
              <th>Name</th>
              <th>Certificate Type</th>
              <th>Resolved At</th>
            </tr>
          </thead>
          <tbody>
            {medRequests?.map((req) => (
              <tr key={req.id}>
                <td>
                  <Link
                    to={`${Routes.APP_POSTS}/${req.id}`}
                    className="text-violet fw-500"
                  >
                    {req.id}
                  </Link>
                </td>
                <td>
                  {req.firstName} {req.lastName}
                </td>
                <td>
                  {" "}
                  {req.type
                    ? capitalizeFirstLetter(req.type.replace(/_/g, " "))
                    : "Sick leave certificate"}
                </td>
                <td>{moment(req.updatedAt).format("DD-MM-YYYY, HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DoctorAnalyticsComponent;
