import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "./style.css";

import HeaderComponent from "../header";
import {
  add,
  update,
  uploadFile,
  removeFile,
  getQuery,
  firestore,
} from "firebase_config";
import { Tables, Routes, Products } from "constants/index";

const currency = "INR";
const products = Products.fitToWork;

const CreateFcComponent = ({ currentUser, location }) => {
  const [options, setOptions] = useState({
    status: "pending",
    confirmGuidelines: false,
    confirmTnc: false,
    leaveReason: "Fitness",
    leaveFrom: "studies",
    firstName: "",
    lastName: "",
    dob: "",
    age: "",
    address: {
      country: "India",
    },
    mobile: "",
    email: "",
    reason: "",
    otherReason: "",
    reasonDescription: "",
    leaveDateFrom: "",
    leaveDateTo: "",
    orgAddress: "",
    orgName: "",
    idFront: "",
    idBack: "",
    optionalDoc: "",
    videoDoc: "",
    gender: "male",
    userId: currentUser.id,
    assigned: false,
    product: products[0],
    paymentStatus: "pending",
    doctorRemarks: [],
    additionalInfoRequired: false,
    height: "",
    heightMeasurement: "cm",
    weight: "",
    weightMeasurement: "kg",
    type: "fitness_certificate",
    subType: location.state?.type || "",
    promocode: "",
    isPrescriptionRequired: false,
    isEditRequired: false,
    orgLocation: "india",
    defaultMessage:
      "I also certify that I have examined the required medical history and have taken them into consideration in arriving at my decision that the patient is currently fit for all types of regular activities.",
  });
  const history = useHistory();
  const [smallDevice, setSmallDevice] = useState(false);
  const [applyDiscount, setApplyDiscount] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [savingCertificate, setSavingCertificate] = useState(false);
  const [error, setError] = useState({
    message: "",
    key: "",
  });
  const [loaders, setLoaders] = useState({
    videoDoc: false,
    optionalDoc: false,
    idFront: false,
    idBack: false,
  });

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  });

  const handleChange = (key, value, additionalField) => {
    if (additionalField) {
      setOptions({
        ...options,
        [key]: {
          ...options[key],
          [additionalField]: value,
        },
      });
    } else {
      setOptions({
        ...options,
        [key]: value,
      });
    }
    if (key === "product") {
      verifyPromoCode(value);
    }
  };

  const uploadImage = async (file, key, event) => {
    if (!file) {
      toast.error("New image required");
      return null;
    }
    setLoaders((prev) => ({
      ...prev,
      [key]: true,
    }));
    await uploadFile(file, "image", async (url, err) => {
      if (err) {
        alert(err);
        return null;
      }
      event.target.value = null;
      setOptions((prevState) => ({
        ...prevState,
        [key]: url,
      }));
      setLoaders((prev) => ({
        ...prev,
        [key]: false,
      }));
    });
  };

  const deleteImage = async (url, key, event) => {
    if (window.confirm("Are you sure to remove image?")) {
      setLoaders((prev) => ({
        ...prev,
        [key]: true,
      }));
      await removeFile(url);
      setLoaders((prev) => ({
        ...prev,
        [key]: false,
      }));
      setOptions((prevState) => ({
        ...prevState,
        [key]: "",
      }));
    }
  };

  const redirectToGuildelines = () => {
    if (
      window.confirm("Your form data will be lost. Are you sure to redirect?")
    ) {
      history.push({
        pathname: Routes.APP_GUIDELINES_SLC,
        state: {
          options,
        },
      });
    }
  };

  const showErrors = () => {
    let error;
    if (!options.firstName) {
      error = {
        message: "First name is required",
        key: "firstName",
      };
    } else if (!options.lastName) {
      error = {
        message: "Last name is required",
        key: "lastName",
      };
    } else if (!options.dob) {
      error = {
        message: "Date of birth is required",
        key: "dob",
      };
    } else if (!options.age) {
      error = {
        message: "Age is required",
        key: "age",
      };
    } else if (!options.email) {
      error = {
        message: "Email is required",
        key: "email",
      };
    } else if (!options.mobile) {
      error = {
        message: "Mobile number is required",
        key: "mobile",
      };
    } else if (
      options.mobile &&
      options.orgLocation === "india" &&
      options.mobile.length !== 12
    ) {
      error = {
        message: "Mobile number must contain 12-digits",
        key: "mobile",
      };
    } else if (!options.height) {
      error = {
        message: "Height is required",
        key: "height",
      };
    } else if (!options.weight) {
      error = {
        message: "Weight is required",
        key: "weight",
      };
    } else if (!options.orgName) {
      error = {
        message: "Organization name is required",
        key: "orgName",
      };
    } else if (!options.orgAddress) {
      error = {
        message: "Organization address is required",
        key: "orgAddress",
      };
    } else if (!options.confirmTnc) {
      error = {
        message: "Please confirm our Terms and Conditions",
        key: "confirmTnc",
      };
    } else if (!options.confirmGuidelines) {
      error = {
        message: "Please confirm our Guidelines",
        key: "confirmGuidelines",
      };
    }
    setError(error);
    return error;
  };

  const saveCertificate = async () => {
    const err = await showErrors();
    if (err && err.message) {
      toast.error(err.message);
      return;
    }

    setSavingCertificate(true);
    options.priority = options.product.priority;

    options.product = {
      ...options.product,
      price: parseInt(totalCost()),
    };

    const res = await add(Tables.MEDICAL_REQUESTS, options);
    setSavingCertificate(false);
    if (res.status === 200) {
      toast.success(
        "Your request has been saved. Complete the payment to proceed further."
      );
      history.push({
        pathname: Routes.APP_RECHARGE,
        state: {
          amount: options.product.price,
          requestId: res.data.id,
        },
      });
    }
  };

  const verifyPromoCode = async (val) => {
    const cc = await getQuery(
      firestore
        .collection(Tables.PROMOCODES)
        .where("code", "==", options.promocode)
        .get()
    );
    const pcode = cc[0];
    if (pcode && pcode.code === options.promocode) {
      if (pcode.type === "limited" && pcode.count > 0) {
        // limited promocodes
        update(Tables.PROMOCODES, pcode.id, {
          count: pcode.count - 1,
        });
        let dp = options.product.price * (1 - pcode.discountPercent);
        if (val) {
          dp = val.price * (1 - pcode.discountPercent);
        }
        setApplyDiscount(pcode);
        setDiscountPrice(dp);
        setError({
          key: "",
          message: "",
        });
      } else if (pcode.type !== "limited") {
        // if type is influencer
        let dp = options.product.price * (1 - pcode.discountPercent);
        if (val) {
          dp = val.price * (1 - pcode.discountPercent);
        }
        setApplyDiscount(pcode);
        setDiscountPrice(dp);
        setError({
          key: "",
          message: "",
        });
      } else {
        setError({
          key: "promocode",
          message: "Code is invalid",
        });
      }
    } else if (options.promocode && !cc[0]) {
      setError({
        key: "promocode",
        message: "Code is invalid",
      });
    }
  };

  const totalCost = () => {
    if (discountPrice) {
      let p = discountPrice;
      if (options.isPrescriptionRequired) {
        p += options.product.prescriptionPrice;
      } else {
        p = discountPrice;
      }
      return p.toFixed(2);
    } else if (options.orgLocation === "abroad") {
      let p = options.product.abroadPrice;
      if (options.isPrescriptionRequired) {
        p += options.product.prescriptionPrice;
      } else {
        p = options.product.abroadPrice;
      }
      return p.toFixed(2);
    } else {
      let p = options.product.price;
      if (options.isPrescriptionRequired) {
        p += options.product.prescriptionPrice;
      } else {
        p = options.product.price;
      }
      return p.toFixed(2);
    }
  };

  return (
    <>
      <HeaderComponent
        headerText={location.state?.headerText || "Medical Certificate"}
      />
      <div className="dashboard-content">
        <div className="container create-post-wrapper pt-5 mt-sm-5">
          <div className="card p-2 p-sm-4 my-sm-5">
            <div className="mt-2">
              <h6>
                Full name of the person who requires certificate * <br />
                <small className="text-secondary fs-10">
                  *As per Govt ID proof
                </small>
              </h6>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className={`form-control font-small ${
                      error?.key === "firstName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="First name"
                    onChange={(e) => handleChange("firstName", e.target.value)}
                  />
                  {error?.key === "firstName" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">First name</small>
                  )}
                </div>
                <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                  <input
                    type="text"
                    className={`form-control font-small ${
                      error?.key === "lastName" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Last name"
                    onChange={(e) => handleChange("lastName", e.target.value)}
                  />
                  {error?.key === "lastName" ? (
                    <small className="text-danger fs-10">{error.message}</small>
                  ) : (
                    <small className="text-secondary fs-10">Last name</small>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-6">
                <h6>Date of birth *</h6>
                <input
                  type="date"
                  className={`form-control ${
                    error?.key === "dob" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="dob"
                  onChange={(e) => handleChange("dob", e.target.value)}
                />
                {error?.key === "dob" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
              <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                <h6>Age *</h6>
                <input
                  type="number"
                  className={`form-control ${
                    error?.key === "age" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="Age"
                  onChange={(e) => handleChange("age", e.target.value)}
                />
                {error?.key === "age" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
            </div>
            <div className="mt-4">
              <h6>Gender *</h6>
              <select
                className="form-control font-small"
                aria-label=".form-select-sm"
                onChange={(e) => handleChange("gender", e.target.value)}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-sm-6">
                <h6 htmlFor="form-question">Email *</h6>
                <input
                  type="email"
                  className={`form-control ${
                    error?.key === "email" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="Email"
                  onChange={(e) => handleChange("email", e.target.value)}
                />
                {error?.key === "email" && (
                  <small className="text-danger fs-10">{error.message}</small>
                )}
              </div>
              <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                <h6 htmlFor="form-question">Mobile *</h6>
                <input
                  type="number"
                  className={`form-control ${
                    error?.key === "mobile" && "error-border"
                  }`}
                  name="form-question"
                  placeholder="917890789090"
                  onChange={(e) => handleChange("mobile", e.target.value)}
                />
                <small className="text-secondary fs-10">
                  e.g. 917890789078
                </small>
                {error?.key === "mobile" && (
                  <div className="text-danger fs-10">{error.message}</div>
                )}
              </div>
            </div>
            <div className="row mt-4 measurements">
              <div className="col-12 col-sm-6">
                <h6 htmlFor="form-question">Height *</h6>
                <div className="input-group">
                  <input
                    type="number"
                    className={`form-control ${
                      error?.key === "height" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Height (in cm)"
                    onChange={(e) => handleChange("height", e.target.value)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon1">
                      cm
                    </span>
                  </div>
                </div>
                {error?.key === "height" && (
                  <div className="text-danger fs-10">{error.message}</div>
                )}
              </div>
              <div className="col-12 col-sm-6 mt-4 mt-sm-0">
                <h6 htmlFor="form-question">Weight *</h6>
                <div className="input-group">
                  <input
                    type="number"
                    className={`form-control ${
                      error?.key === "weight" && "error-border"
                    }`}
                    name="form-question"
                    placeholder="Weight (in Kg)"
                    onChange={(e) => handleChange("weight", e.target.value)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon1">
                      Kg
                    </span>
                  </div>
                </div>
                {error?.key === "weight" && (
                  <div className="text-danger fs-10">{error.message}</div>
                )}
              </div>
            </div>
            <hr />
            <div className="text-secondary fs-10 fw-400 mt-1">
              No medical certificate is issued as "Whomsoever It May Concern" as
              the National Medical Council guidelines state that a medical
              certificate has to be addressed to an organisation
              (School/College/Company){" "}
            </div>
            <div className="mt-3">
              <h6>Organization Name*</h6>
              <input
                type="text"
                className={`form-control col-12 ${
                  error?.key === "orgName" && "error-border"
                }`}
                placeholder="Organization Name"
                onChange={(e) => handleChange("orgName", e.target.value)}
              />
              {error?.key === "orgName" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            <div className="mt-4">
              <h6>
                Organization Located in *
                <div className="text-secondary fs-10 fw-400 mt-1">
                  Organization address should match with its location{" "}
                </div>
              </h6>
              <select
                className="form-control font-small"
                aria-label=".form-select-sm"
                onChange={(e) => handleChange("orgLocation", e.target.value)}
              >
                <option value="india" defaultValue>
                  India
                </option>
                <option value="abroad">Outside India</option>
              </select>
            </div>
            <div className="mt-4">
              <h6>Organization Address *</h6>
              <textarea
                className={`form-control fs-12 ${
                  error?.key === "orgAddress" && "error-border"
                }`}
                name="form-question"
                placeholder="Complete Organization Address"
                onChange={(e) => handleChange("orgAddress", e.target.value)}
              />
              {error?.key === "orgAddress" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            {/* medical problem */}
            <div className="mt-4 d-none">
              <h6>
                Video evidence *
                <div className="text-secondary fs-10 pt-2 fw-400">
                  Only video formats supported.
                </div>
              </h6>
              <div className="text-center image-label">
                <div className={options.videoDoc && "d-none"}>
                  <label htmlFor="videoDoc" className="pointer">
                    {loaders.videoDoc ? (
                      <span className="fs-10">
                        Our system is working. Sometimes it takes a while...
                        <br />
                        <i className="fa fa-spinner fa-spin" />
                      </span>
                    ) : (
                      <span>
                        Upload <i className="fa fa-arrow-circle-o-up" /> <br />
                        <small className="fs-12">
                          15 secs full body video of you walking
                        </small>
                      </span>
                    )}
                  </label>
                  <input
                    type="file"
                    className="form-control-plaintext d-none"
                    id="videoDoc"
                    onChange={(e) =>
                      uploadImage(e.target.files[0], "videoDoc", e)
                    }
                    accept="video/*"
                  />
                </div>
                {options.videoDoc && (
                  <div className="">
                    <object
                      data={options.videoDoc}
                      className="camera-icon"
                      width="250px"
                      height="150px"
                    />
                    <button
                      className="btn btn-violet btn-sm"
                      onClick={(e) =>
                        deleteImage(options.videoDoc, "videoDoc", e)
                      }
                    >
                      Delete File
                    </button>{" "}
                  </div>
                )}
              </div>
              {error?.key === "videoDoc" && (
                <small className="text-danger fs-10">{error.message}</small>
              )}
            </div>
            {/* documents */}
            <div className="text-orange-600 fw-500 mt-5 text-center">
              Summer Offer: 25% discount on all products
            </div>
            <div className="mt-4">
              <h6>My products *</h6>
              <div className="row">
                {products.map(
                  (product, idx) =>
                    product.display && (
                      <div key={idx} className="col-12 my-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="product"
                          id={`product-${idx}`}
                          onChange={(e) => handleChange("product", product)}
                          checked={options.product.name === product.name}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`product-${idx}`}
                        >
                          <div className="media">
                            <div className="media-body">
                              <div className="fw-500">
                                {product.name} {smallDevice && <div></div>}
                                <div
                                  className={
                                    smallDevice ? "my-2" : "pull-right"
                                  }
                                >
                                  {options.orgLocation === "abroad"
                                    ? product.abroadPrice.toFixed(2)
                                    : product.price.toFixed(2)}{" "}
                                  {currency} &nbsp;
                                  <strike className="text-orange-600 fw-500 mt-2 fs-12">
                                    {options.orgLocation === "abroad"
                                      ? product.abroadTotalPrice.toFixed(2)
                                      : product.totalPrice.toFixed(2)}{" "}
                                    {currency}
                                  </strike>
                                </div>
                              </div>
                              <div className="mt-1 fs-12">
                                {product.description}
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    )
                )}
              </div>
              <div className="mt-4 ml-4">
                <div className="form-question fs-12">Have a Referral Code?</div>
                <div className="input-group col-12 col-sm-6 pl-0">
                  <input
                    type="text"
                    className={`form-control fs-12 ${
                      error?.key === "promocode" && "error-border"
                    }`}
                    placeholder="Referral code"
                    aria-label="Referral Code"
                    aria-describedby="promo-code"
                    onChange={(e) => handleChange("promocode", e.target.value)}
                    disabled={discountPrice}
                  />
                  <div
                    className="input-group-append"
                    onClick={(e) => verifyPromoCode()}
                  >
                    <span
                      className="btn btn-violet btn-sm px-3"
                      id="promo-code"
                      disabled={discountPrice}
                    >
                      {applyDiscount ? "Applied!" : "Apply"}
                    </span>
                  </div>
                </div>
                <small className="text-secondary fs-10">e.g. MEDOC_20D</small>
                {error?.key === "promocode" && (
                  <div className="text-danger fs-10">{error.message}</div>
                )}
              </div>
              <div className="mt-4 text-center">
                <input
                  className="form-check-input ml-1 mt-2"
                  type="checkbox"
                  name="isPrescriptionRequired"
                  id="isPrescriptionRequired"
                  onChange={(e) =>
                    handleChange(
                      "isPrescriptionRequired",
                      !options.isPrescriptionRequired
                    )
                  }
                  checked={options.isPrescriptionRequired}
                />
                <label
                  className={`form-check-label ml-4 mt-1 text-justify ${
                    error?.key === "isPrescriptionRequired" && "error-color"
                  }`}
                  htmlFor="isPrescriptionRequired"
                >
                  <small className="fw-500">
                    Get prescription for {options.product.prescriptionPrice}
                    .00 INR /-
                  </small>
                </label>
              </div>
              <div className="mt-2 text-center fs-16 fw-500">
                Total cost:{" "}
                <span className="text-violet">
                  {totalCost()}
                  {currency}
                  {discountPrice && options.product.price && (
                    <strike className="text-orange-600 pl-2">
                      {options.product.price.toFixed(2)} {currency}
                    </strike>
                  )}
                </span>
              </div>
              {discountPrice && (
                <div className="text-orange-600 text-center pt-3 fs-12">
                  Hurray! You saved extra {applyDiscount.discountPercent * 100}%
                </div>
              )}
              <hr />
              <div className="tnc">
                <div className="pt-2">
                  <input
                    className="form-check-input ml-1 mt-2"
                    type="checkbox"
                    name="confirmGuidelines"
                    id="confirmGuidelines"
                    onChange={(e) => handleChange("confirmGuidelines", true)}
                    checked={options.confirmGuidelines}
                  />
                  <label
                    className="form-check-label ml-4 text-justify"
                    htmlFor="confirmGuidelines"
                  >
                    I adhere to all our{" "}
                    <span
                      className="text-violet px-0 mx-0"
                      onClick={redirectToGuildelines}
                    >
                      medoc guidelines.
                    </span>
                  </label>
                </div>
              </div>
              <div className="tnc">
                <div className="pt-2">
                  <input
                    className="form-check-input ml-1"
                    type="checkbox"
                    name="confirmTnc"
                    id="confirmTnc"
                    onChange={(e) => handleChange("confirmTnc", true)}
                    checked={options.confirmTnc}
                  />
                  <label
                    className="form-check-label ml-4 text-justify"
                    htmlFor="confirmTnc"
                  >
                    I confirm that I am not seriously unwell and I do NOT have
                    any of the following symptoms: chest pain, shortness of
                    breath, unable to swallow fluids or saliva, weakness or
                    numbness down one side, and slurred speech. I confirm I do
                    not think I need to see a General Practitioner. If symptoms
                    are related to a COVID vaccine, I will consult my General
                    Practitioner or the medical professional who provided the
                    immunization. I agree that the issuance of a medical
                    certificate and its duration is based on the discretion of
                    the consulting doctor. I confirm that I am not using the
                    certificate for any medico-legal purposes. In case of
                    non-issuance of the certificate due to any reason, a service
                    charge of INR {totalCost() >= 1000 ? 500 : 250}/- will be
                    deducted while refunding the amount. The refund will be done
                    online and usually takes 7-10 working days. I also agree to
                    the{" "}
                    <Link to="/rp" target="_blank" className="text-violet">
                      Refund and cancellation policy
                    </Link>
                    . I confirm that this online consultation is not a
                    replacement for a doctor's visit. I have read, understood,
                    and agree with the information in the terms of service and
                    consent to the use of information in accordance with the
                    privacy policy.
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            className="complete-payment btn btn-violet text-center"
            onClick={saveCertificate}
          >
            {savingCertificate ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <span>Complete Payment</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateFcComponent;
