import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { Tables } from "constants/index";
import { getQuery, firestore } from "firebase_config";

const FilterAppointmentsComponent = ({
  currentUser,
  requests,
  setRequests,
}) => {
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    searchValue: "",
    startDate: "",
    endDate: "",
  });

  useEffect(async () => {
    setLoading(true);
    let req = await getQuery(
      firestore
        .collection(Tables.DC_PUBLIC_APPOINTMENTS)
        .where("doctorId", "==", currentUser.id)
        .where("paymentStatus", "==", "completed")
        .orderBy("updatedAt", "desc")
        .get()
    );
    req = req.sort((a, b) => b.updatedAt - a.updatedAt);
    setRequests(req);
    setLoading(false);
  }, []);

  const reloadRecords = async () => {
    const req = await getQuery(
      firestore
        .collection(Tables.DC_PUBLIC_APPOINTMENTS)
        .where("doctorId", "==", currentUser.id)
        .where("paymentStatus", "==", "completed")
        .orderBy("updatedAt", "desc")
        .get()
    );
    return req.sort((a, b) => b.updatedAt - a.updatedAt);
  };

  const searchRecords = async () => {
    const startDate = new Date(filters?.startDate).getTime();
    const endDate = new Date(filters?.endDate).getTime();
    if (filters.startDate && filters.endDate && startDate > endDate) {
      toast.error("Start Date must be less than End Date");
      return;
    }

    setLoading(true);
    let filteredRecords = await reloadRecords();
    if (filters.searchValue) {
      filteredRecords = filteredRecords.filter(
        (req) =>
          req.id === filters.searchValue ||
          req.email === filters.searchValue ||
          filters.searchValue === req.name
      );
    }
    if (filters.startDate) {
      filteredRecords = filteredRecords.filter(
        (req) => req.createdAt >= startDate
      );
    }

    if (filters.endDate) {
      filteredRecords = filteredRecords.filter(
        (req) => req.createdAt <= endDate
      );
    }
    setRequests(filteredRecords);
    setLoading(false);
  };

  const handleChange = async (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const clearSearch = async () => {
    const filteredRecords = await reloadRecords();
    setRequests(filteredRecords);
    setFilters({
      searchValue: "",
      startDate: "",
      endDate: "",
    });
  };

  return (
    <>
      <div className="card p-3 mx-2 mx-sm-0">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="fs-22 fw-500">Filters</div>
          <div
            className={`fs-14 fw-500 pointer ${
              showFilters ? "text-danger" : "text-violet"
            }`}
            onClick={(e) => setShowFilters(!showFilters)}
          >
            {showFilters ? "Hide" : "Show"}
          </div>
        </div>
        {showFilters && (
          <>
            <div className="row mt-2">
              <div className="col-12 col-sm-12">
                <label htmlFor="form-question" className="fs-12 fw-500">
                  Enter Email / Request number / Full name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="form-question"
                  placeholder="Email / Request number / Full name"
                  value={filters.searchValue}
                  onChange={(e) =>
                    handleChange("searchValue", e.target.value.trim())
                  }
                />
              </div>
              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="start-date" className="fs-12 fw-500">
                  Start Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="start-date"
                  placeholder="DD/MM/YYYY"
                  max={filters.endDate}
                  value={filters.startDate}
                  onChange={(e) => handleChange("startDate", e.target.value)}
                />
              </div>
              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="end-date" className="fs-12 fw-500">
                  End Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="end-date"
                  placeholder="DD/MM/YYYY"
                  min={filters.startDate}
                  value={filters.endDate}
                  onChange={(e) => handleChange("endDate", e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex flex-row">
              <div
                className="btn btn-violet btn-sm mt-2"
                onClick={searchRecords}
              >
                Search
              </div>
              <div
                className="btn btn-outline-danger btn-sm mt-2 ml-2"
                onClick={clearSearch}
              >
                Clear
              </div>
            </div>
          </>
        )}
        {loading && <i className="fa fa-spinner fa-spin" />}
      </div>
    </>
  );
};

export default FilterAppointmentsComponent;
