import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { post } from "services";
import { update } from "firebase_config";
import { Tables } from "constants/index";

const AcceptComponent = ({
  currentUser,
  request,
  options,
  setOptions,
  approveFlag,
  setApproveFlag,
}) => {
  const [loading, setLoading] = useState(false);
  const [doctorRemarks, setDoctorRemarks] = useState();
  const history = useHistory();

  const handleChange = (key, value, additionalField) => {
    if (additionalField) {
      setOptions({
        ...options,
        [key]: {
          ...options[key],
          [additionalField]: value,
        },
      });
    } else {
      setOptions({
        ...options,
        [key]: value,
      });
    }
  };

  const approveRequest = async () => {
    if (!doctorRemarks) {
      toast.error("Feedback is required");
      return;
    }
    setLoading(true);
    const res = await update(Tables.DC_PUBLIC_APPOINTMENTS, request.id, {
      status: "accepted",
      resolvedAt: new Date().getTime(),
      doctorRemarks,
    });
    if (res.status === 200) {
      const earnings = +request.product?.cost * 0.9; // Service charge: 10%
      await update(Tables.USERS, currentUser.id, {
        withdrawAmount: currentUser.withdrawAmount + earnings,
      });
      toast.success("Request is approved.");
      await post("/send_email", {
        templateName: "send_public_doctor_response.html",
        email: request.email,
        name: request.name,
        doctorName: currentUser.displayName,
        doctorRemarks,
        prescriptionLink: request.prescriptionLinkShort,
        requestId: request.id,
      });
      history.go(0);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="col-12">
        <div className="mt-3">
          <div className="font-small fw-500 text-secondary">
            Feedback to patient:
          </div>
          <div>
            <textarea
              className="form-control font-small mt-1"
              placeholder="This message is sent to patient via email"
              onChange={(e) => setDoctorRemarks(e.target.value)}
            />
          </div>
        </div>
        <hr />
        <div className="d-none">
          <h6>Extra Verification</h6>
          <div className="mt-3">
            <div className="font-small fw-500 text-secondary">
              Is customer identification verified? <br />
              <small>
                Name and adddress must match with the uploaded documents.
              </small>
            </div>
            <div className="col-12 py-1 ml-1 fs-12 pl-0">
              <select
                className="form-control font-small mt-1"
                aria-label=".form-select-sm"
                onChange={(e) =>
                  handleChange(
                    "extraDetails",
                    e.target.value,
                    "customerVerification"
                  )
                }
              >
                <option value="id_not_verified">No, I didn't verify ID.</option>
                <option value="id_verified_correct_details">
                  Yes, ID is verified and all details are correct.
                </option>
                <option value="id_verified_incorrect_details">
                  Yes, ID is verified and details are incorrect.
                </option>
              </select>
            </div>
          </div>
          <div className="mt-3">
            <div className="font-small fw-500 text-secondary">
              Is organization name and address correct? <br />
              <small className="d-none">
                It is your responsibility to correct the details if its not.
              </small>
            </div>
            <div className="col-12 py-1 ml-1 fs-12 pl-0">
              <select
                className="form-control font-small mt-1"
                aria-label=".form-select-sm"
                onChange={(e) =>
                  handleChange(
                    "extraDetails",
                    e.target.value,
                    "customerOrganizationVerification"
                  )
                }
              >
                <option value="organization_not_verified">
                  No, I didn't check. I don't care.
                </option>
                <option value="organization_verified_correct_details">
                  Yes, I asked and confirmed by patient. All details are
                  correct.
                </option>
              </select>
            </div>
          </div>
          <div className="mt-3">
            <div className="font-small fw-500 text-secondary">
              How'd the customer know about Medoc? <br />
            </div>
            <select
              className="form-control font-small"
              aria-label=".form-select-sm"
              onChange={(e) =>
                handleChange(
                  "extraDetails",
                  e.target.value,
                  "customerVisitedVia"
                )
              }
            >
              <option value="nothing">I didn't ask</option>
              <option value="social_media">Social media</option>
              <option value="online_search">Online search</option>
              <option value="friend_referral">Friend Referral</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="mt-3">
            <div className="font-small fw-500 text-secondary">
              Did you ask patient to provide feedback about their experience?{" "}
              <br />
            </div>
            <select
              className="form-control font-small mt-1"
              aria-label=".form-select-sm"
              onChange={(e) =>
                handleChange(
                  "extraDetails",
                  e.target.value,
                  "askedCustomerFeedback"
                )
              }
            >
              <option value="no">No, I didn't ask</option>
              <option value="yes">Yes, I asked</option>
            </select>
          </div>
        </div>
        <div className="mt-3 col-12 text-center">
          <button
            className="btn btn-violet btn-sm py-2 my-2 col-8 col-sm-6"
            onClick={approveRequest}
            disabled={loading}
          >
            Send Feedback {loading && <div className="spinner-border" />}
          </button>{" "}
          <button
            className="btn btn-danger-outline btn-sm px-3 my-2 col-6"
            onClick={(e) => setApproveFlag(false)}
            disabled={loading}
          >
            Cancel
          </button>{" "}
        </div>
      </div>
    </>
  );
};

export default AcceptComponent;
