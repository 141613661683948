import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { capitalizeFirstLetter } from "helpers";
import { getQuery, firestore } from "firebase_config";
import { Routes, Tables } from "constants/index";
import { HeaderComponent, LoadingComponent } from "components";

function GetClientRequestsComponent({ currentUser }) {
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState();

  useEffect(() => {
    async function getRequests() {
      setLoading(true);
      let res = await getQuery(
        firestore
          .collection(Tables.ORGANIZATION_REQUESTS)
          .orderBy("updatedAt", "desc")
          .get()
      );
      res = res.sort((a, b) => b.createdAt - a.createdAt);
      setRequests(res);
      setLoading(false);
    }

    if (!requests) {
      getRequests();
    }
  });

  return (
    <div className="">
      <HeaderComponent />
      <div className="container settings-wrapper desktop-align-center pt-5">
        <div className="d-flex flex-row my-2 fw-500 align-items-center">
          <div>Get Organization Requests</div>
          <Link to={Routes.APP_POSTS} className="btn btn-violet btn-sm ml-3">
            Go Back
          </Link>
        </div>
        <table className="table table-bordered table-responsive">
          <thead>
            <tr>
              <th>Request Number</th>
              <th>Name</th>
              <th>Email</th>
              <th>Request Id</th>
              <th>Status</th>
              <th>Requested At</th>
            </tr>
          </thead>
          <tbody>
            {!loading && requests ? (
              requests.map((req) => (
                <tr key={req.id}>
                  <td>{req.id}</td>
                  <td>{req.name}</td>
                  <td>{req.email}</td>
                  <td>{req.requestId}</td>
                  <td>
                    {req.status ? capitalizeFirstLetter(req.status) : "Pending"}
                  </td>
                  <td>{moment(req.createdAt).format("DD-MM-YYYY, HH:mm")}</td>
                </tr>
              ))
            ) : (
              <LoadingComponent />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default GetClientRequestsComponent;
