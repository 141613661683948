import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";

import { capitalizeFirstLetter } from "helpers";
import { Tables, Routes } from "constants/index";
import { getId, update } from "firebase_config";
import { HeaderComponent } from "components";
import AcceptComponent from "./accept";
import RejectComponent from "./reject";
import AmiComponent from "./ami";

const ShowAppointmentComponent = (props) => {
  const { currentUser } = props;
  const [loading, setLoading] = useState(false);
  const [calling, setCalling] = useState(false);
  const [request, setRequest] = useState();
  const [approveFlag, setApproveFlag] = useState(false);
  const [rejectFlag, setRejectFlag] = useState(false);
  const [amiFlag, setAmiFlag] = useState(false);
  const [newMobile, setNewMobile] = useState();
  const [newEmail, setNewEmail] = useState();
  const [options, setOptions] = useState({
    reason: "",
    leaveDateTo: "",
    leaveDateFrom: "",
    extraDetails: {
      customerVerification: "id_not_verified",
      customerVisitedVia: "nothing",
      askedCustomerFeedback: "no",
    },
  });
  const history = useHistory();
  const appointmentId = props.computedMatch?.params?.id;

  useEffect(async () => {
    setLoading(true);
    const req = await getId(Tables.DC_PUBLIC_APPOINTMENTS, appointmentId);
    if (!req) {
      toast.error("No Appointment Found");
      return;
    }
    setRequest({
      ...req,
      id: appointmentId,
    });
    setNewMobile(req.mobile);
    setNewEmail(req.email);
    setOptions({
      ...options,
      prescription: req.prescription,
      prescriptionLink: req.prescriptionLink,
    });
    setLoading(false);
  }, []);

  const callPatient = async () => {
    if (!newMobile || newMobile?.length !== 12) {
      toast.error("Mobile number must contain 12-digits");
      return;
    }
    setCalling(true);
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    const companyNumber = process.env.REACT_APP_TWILIO_NUMBER;
    // Sample mobile numbers: 919246146364, 447438461617
    const data = `To="${currentUser.mobile}"&From=${companyNumber}&Parameters={"patient_number":"${newMobile}","patient_name": "${request.firstName}"}`;
    const flowUrl =
      "https://studio.twilio.com/v2/Flows/FW72e68dfea557b4132dfb0b59337131ee/Executions";
    if (companyNumber && data) {
      toast.success(
        "You will receive a call in 15 secs. Please check your phone."
      );
      const res = await axios.post(flowUrl, data, {
        headers,
        auth: {
          username: process.env.REACT_APP_TWILIO_USER_NAME,
          password: process.env.REACT_APP_TWILIO_PASSWORD,
        },
      });
      if (res.status === 201) {
        setTimeout(() => {
          toast.success("We're connecting your call to patient...");
        }, 5000);
      }
    }
    setCalling(false);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied text from clipboard.");
  };

  const deletePrescription = async () => {
    if (window.confirm("Are you sure to delete prescription?")) {
      await update(Tables.DC_PUBLIC_APPOINTMENTS, request.id, {
        prescription: {},
        prescriptionLink: "",
      });
      setOptions({
        prescription: {},
        prescriptionLink: "",
      });
    }
  };

  const redirectToCreatePage = () => {
    history.push({
      pathname: `${Routes.APP_DC_PUBLIC_APPOINTMENTS}/${request.id}/prescription`,
      state: {
        request,
      },
    });
  };

  const redirectToUpdatePage = () => {
    history.push({
      pathname: Routes.APP_UPDATE_MEDICAL_REQUEST,
      state: {
        request,
      },
    });
  };

  const goBack = () => {
    history.push(Routes.APP_DC_PUBLIC_APPOINTMENTS);
  };

  const videoCallPatient = async () => {
    if (confirm("Are you sure to do video call with patient?")) {
      window.open("https://meet.google.com/new?hs=30&authuser=0");
    }
  };

  return loading ? (
    <div className="dashboard-content text-center mt-5 pt-5">
      <div className="spinner-border"></div>
    </div>
  ) : (
    <div className="dashboard-content mt-5 pt-5">
      <HeaderComponent />
      {request ? (
        <div className="card p-3">
          <div
            className="btn btn-violet btn-sm col-6 col-sm-2"
            onClick={goBack}
          >
            Go back
          </div>

          <div className="row">
            <div
              className={`col-12 pointer text-right ${
                request.type === "fitness_certificate" || !request.type
                  ? "text-violet"
                  : "d-none"
              }`}
              onClick={redirectToUpdatePage}
            >
              <u>
                <i className="fa fa-pencil mr-2" />
                Edit request
              </u>
            </div>
            <div className="mt-3 col-12 col-sm-6">
              <div className="font-small fw-500 text-secondary">Status</div>
              <div>{capitalizeFirstLetter(request.status)}</div>
            </div>
            <div className="mt-3 col-12 col-sm-6">
              <div className="font-small fw-500 text-secondary">
                Request number
              </div>
              <div>
                {request.id}
                <i
                  className="fa fa-copy fs-12 pointer ml-2 btn btn-violet-outline btn-sm"
                  title="Copy"
                  onClick={(e) => copyToClipboard(request.id)}
                />
              </div>
            </div>
            <div className="mt-3 col-12 col-sm-6">
              <div className="font-small fw-500 text-secondary">
                Request type
              </div>
              <div>{capitalizeFirstLetter(request.type)}</div>
            </div>
            <div className="mt-3 col-12 col-sm-6">
              <div className="font-small fw-500 text-secondary">
                Last updated
              </div>
              <div>{moment(request.updatedAt).format("DD-MM-YYYY, HH:mm")}</div>
            </div>
            <div className="mt-3 col-12">
              <div className="font-small fw-500 text-secondary">Name</div>
              <div>{request.name}</div>
            </div>
            {request.bookedSlot && (
              <div className="mt-3 col-12">
                <div className="font-small fw-500 text-secondary">
                  Booked Slot
                </div>
                <div>{request.bookedSlot}</div>
              </div>
            )}
            {request.files && request.files.length && (
              <div className="mt-3 col-12">
                <div className="font-small fw-500 text-secondary">
                  Optional Documents
                </div>
                {request.files.map((file, i) => (
                  <div className="mt-2" key={i}>
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-violet"
                    >
                      Document {i + 1}
                    </a>
                  </div>
                ))}
              </div>
            )}
            <div className="mt-3 col-12">
              <div className="font-small fw-500 text-secondary">Message</div>
              <div>{request.message}</div>
            </div>
            <div className="mt-3 col-12">
              <div className="font-small fw-500 text-secondary">
                Product: {request.product?.name || request.product?.title}
              </div>
              <div>{request.product?.cost || request.product?.price} INR/-</div>
            </div>
            {request.doctorRemarks && (
              <div className="mt-3 col-12">
                <div className="font-small fw-500 text-secondary">
                  Doctor remakrs
                </div>
                <div>{request.doctorRemarks}</div>
              </div>
            )}
            {request.doctorRemarkss && request.doctorRemarkss.length > 0 && (
              <div className="mt-3 col-12">
                <div className="font-small fw-500 text-secondary">
                  Doctor remarks
                </div>
                {request.doctorRemarks.map((remark, idx) => (
                  <div className="my-1" key={idx}>
                    {remark.d2p && (
                      <div>
                        <b>doctor to patient</b>: {remark.d2p}{" "}
                        <a
                          href={remark.doc}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`${!remark.doc && "d-none"}`}
                        >
                          <i className="fa fa-paperclip text-violet fs-20 mr-2"></i>
                        </a>
                        <span className="text-secondary fs-10">
                          {moment(remark.createdAt).format("hh:mm DD/MM/YY")}
                        </span>
                      </div>
                    )}
                    {remark.p2d && (
                      <div>
                        <b>patient to doctor</b>: {remark.p2d}{" "}
                        <a
                          href={remark.doc}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`${!remark.doc && "d-none"}`}
                        >
                          <i className="fa fa-paperclip text-violet fs-20 mr-2"></i>
                        </a>
                        <span className="text-secondary fs-10">
                          {moment(remark.createdAt).format("hh:mm DD/MM/YY")}
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {request.status === "pending" && (
              <>
                <div className="d-flex flex-column justify-content-center col-12 col-sm-8">
                  <div className="mt-3">
                    <div className="font-small fw-500 text-secondary">
                      Mobile (ex. 917890XXXXXX)
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        value={newMobile}
                        className="form-control"
                        id="mobile"
                        aria-describedby="basic-addon3"
                        onChange={(e) => setNewMobile(e.target.value)}
                      />
                      <div
                        className="input-group-append pointer"
                        onClick={callPatient}
                        disabled={calling}
                      >
                        <span className="input-group-text">
                          <i className="fa fa-phone pr-2" /> Call
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="font-small fw-500 text-secondary">
                      Email
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        value={newEmail}
                        className="form-control"
                        id="email"
                        onChange={(e) => setNewEmail(e.target.value)}
                      />
                      <div
                        className="input-group-append"
                        onClick={videoCallPatient}
                      >
                        <span className="input-group-text">
                          <i className="fa fa-video-camera pr-1" /> Connect
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {request.isPrescriptionRequired && request.status === "pending" && (
              <div className="col-12 ml-0 text-right text-violet pointer">
                {options.prescriptionLink ? (
                  <div>
                    <a
                      href={options.prescriptionLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-violet"
                    >
                      <i className="fa fa-paperclip text-violet fs-16" />{" "}
                      Prescription Attached
                    </a>
                    <i
                      className="fa fa-trash text-danger ml-2 fs-14"
                      onClick={deletePrescription}
                    />
                  </div>
                ) : (
                  <div onClick={redirectToCreatePage}>
                    <i className="fa fa-plus pr-2" />
                    Create Prescription
                  </div>
                )}
              </div>
            )}
            {request.status === "pending" &&
              !approveFlag &&
              !rejectFlag &&
              !amiFlag && (
                <>
                  <div className="text-center mt-3 col-12">
                    <button
                      className={`btn btn-violet btn-sm py-2 my-2 col-8 col-sm-6 fw-500 ${
                        request.type === "consult_doctor" && "d-none"
                      }`}
                      onClick={(e) => setApproveFlag(true)}
                      disabled={loading}
                    >
                      <i className="fa fa-check pr-1" />
                      Accept
                    </button>{" "}
                    <br
                      className={`${
                        request.type === "consult_doctor" && "d-none"
                      }`}
                    />
                    <button
                      className="btn btn-violet btn-sm px-3 my-2 col-6 d-none"
                      onClick={(e) => setRejectFlag(true)}
                      disabled={loading}
                    >
                      Reject
                    </button>{" "}
                    <br className="d-none" />
                    <button
                      className="btn btn-violet-outline btn-sm px-3 my-2 col-6 col-sm-4 d-none"
                      onClick={(e) => setAmiFlag(true)}
                      disabled={loading}
                    >
                      {request.type === "consult_doctor"
                        ? "Share Feedback"
                        : "Ask More Information"}
                    </button>
                  </div>
                </>
            )}

            {approveFlag && (
              <>
                <AcceptComponent
                  currentUser={currentUser}
                  request={request}
                  approveFlag={approveFlag}
                  setApproveFlag={setApproveFlag}
                  options={options}
                  setOptions={setOptions}
                />
              </>
            )}
            {rejectFlag && (
              <RejectComponent
                currentUser={currentUser}
                request={request}
                setRejectFlag={setRejectFlag}
              />
            )}
            {amiFlag && (
              <AmiComponent
                currentUser={currentUser}
                request={request}
                setAmiFlag={setAmiFlag}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="text-center">No requests yet</div>
      )}
    </div>
  );
};

export default ShowAppointmentComponent;
